import React from "react";
import './about-us.scss';

const AboutUsResources = () => {

  return (
    <>
      <div className="section primary-message">
        <div className="section-inner">
          <h2>Get More Information About Addiction and Mental Health</h2>
          <p>Want to learn more about mental health and substance use issues? These sites can help you find exactly what you need.</p>
        </div>
      </div>

      <div className="section about-us">
        <div className="section-inner">
          <h2>Dealing With Kids and<br />Mental Illness/Addiction</h2>
          <div className="three-column-row">
            <div className="column">
              <p className="item-title">On Our Sleeves</p>
              <a href="https://www.onoursleeves.org/" target="_blank" rel="noreferrer">onoursleeves.org</a>
            </div>
            <div className="column">
              <p className="item-title">Be Present</p>
              <a href="https://bepresentohio.org/" target="_blank" rel="noreferrer">bepresentohio.org</a>
            </div>

            <div className="column">
              <p className="item-title">Start Talking!</p>
              <a href="https://starttalking.ohio.gov/" target="_blank" rel="noreferrer">starttalking.ohio.gov</a>
            </div>

          </div>
        </div>
      </div>
    </>
  )
}

export default AboutUsResources;
