import React from 'react';
import HeadTagManager from '../../utils/HeadTagManager';
import './privacy-policy.scss';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
      <HeadTagManager
        title="Privacy Policy | Ohio Opioid Education Alliance"
        metaTags={[
          {
          name: "description",
          content: "View the Ohio Opioid Education Alliance and Beat the Stigma privacy policy."
          },
          {
            name: 'og:description',
            content: 'View the Ohio Opioid Education Alliance and Beat the Stigma privacy policy.'
          },
          {
            name: 'og:title',
            content: "Privacy Policy | Ohio Opioid Education Alliance"
          }
        ]} />
<div className="section primary-section">
  <div className="section-inner">
    <h2>Terms and conditions of use</h2> 

    <p className="centered">Effective November 15, 2021</p>
    <h3>AGREEMENT</h3>
    <p>By accessing or using the BeatTheStigma.org and related websites, and through our mobile applications (the “Site”), provided by the Nationwide Foundation (“Nationwide Foundation”, “we”, “our”, “us”), you signify that you have read, understood, and agree to be bound by this Terms and Conditions of Use Agreement (“Agreement” or “Terms and Conditions”) and to the collection, use, and sharing of your information as set forth in the Nationwide Foundation <a href="https://www.nationwide.com/personal/privacy-security/pages/privacy" target="_blank" rel="noreferrer">Privacy Policy</a>, whether or not you are a registered user of our Service. This Agreement applies to all visitors, users, and others who access the Service (“Users”). </p> 

    <p>Nationwide Foundation reserves the right to modify or change the Terms and Conditions at any time without prior notice to you. Such modification or change shall be effective upon posting by Nationwide Foundation on the Site. You agree to be bound to any changes to these Terms and Conditions when you use the Site after any such change is posted.</p> 
  </div>
</div>
<div className="section">
  <div className="section-inner">
    <h3>DISCLAIMER OF WARRANTIES</h3> 

    <p>All information and other materials present on the Site (“Content”), including (without limitation) products and services, are provided “as is.” There are no warranties about the Content’s nature or accuracy (either when posted or as a result of the passage of time). There also are no representations or guarantees. </p>

<p>Nationwide Foundation disclaims all warranties, express or implied. This includes, but is not limited to, warranties of merchantability, fitness for a particular purpose, title, non-infringement, security or accuracy with regard to any website owned by a third party to which or from which the Site is linked (“linked website”). Nationwide Foundation does not endorse and is not responsible for the capabilities or reliability of any product or service obtained from a linked website. 
</p>
<p>The information, products, software, or service descriptions published on the Site or a linked website may include inaccuracies or typographical errors. The Nationwide Foundation disclaims liability for such errors and does not warrant or represent that the Content on the Site or a linked website is updated or complete. Nationwide Foundation has no obligation to update any Content on the Site and may change or improve the Site at any time without notice. 
</p>
<p>Nationwide Foundation does not endorse and is not responsible for the accuracy or reliability of any opinion, advice, or statement made through the Site by any party other than Nationwide Foundation. 
</p>
<p>Nationwide Foundation makes no representations, warranties, or guarantees that the Site will be accessible continuously and without interruption or errors. 
</p>
</div>
</div>
<div className="section">
  <div className="section-inner">
    <h2>LIMITATION OF LIABILITIES</h2> 

    <h3>NATIONWIDE FOUNDATION’S LIABILITIES</h3> 

<p>In no event will Nationwide Foundation, its respective service providers, employees, agents, officers and directors, or Nationwide Mutual Insurance Company, its subsidiaries and affiliates be liable to you for any punitive, indirect, direct, special, incidental or consequential damages. This includes any lost profits, costs of obtaining substitute service or lost opportunity, even if you have notified Nationwide Foundation about the possibility of such damages. This also includes any claims by any third parties arising out of (or in any way related to) the access, use, or Content of the Site or a linked website. This applies whether such claims are brought under any theory of law or equity. 
</p>
<p>This limitation on liability includes (but is not limited to) transmission of viruses that infects a user’s equipment, mechanic or electronic equipment failure, failure of communication lines, telephone or other interconnects, unauthorized access, theft, operational errors, strikes or other labor problems, or any force majeure. 
</p>
<p>Nationwide Mutual Insurance Company, its subsidiaries and affiliates are intended third party beneficiaries of this Agreement. 
</p>
    <h3>YOUR LIABILITIES </h3>

<p>Nationwide Foundation has the right to deny you access and use of the Site and its Content if you violate (as Nationwide Foundation may determine in its sole and absolute discretion) any provision of the Terms and Conditions. Nationwide Foundation reserves the right to seek all other remedies available at law and in equity. You agree, at your own expense, to defend, indemnify and hold Nationwide Foundation harmless from any claim or demand (including reasonable attorneys’ fees) made by a third party in connection with (or arising out of your access to, or use of) the Site or any of its Content in a manner other than as expressly authorized by the Terms and Conditions. This includes your breach of the Terms and Conditions, or your violation of applicable laws or any rights of any third party. 
</p>  </div>
</div>
<div className="section">
  <div className="section-inner">
    <h2>PERSONAL INFORMATION</h2> 

<p>Nationwide Foundation takes your privacy and online security very seriously. Our  <a href="https://www.nationwide.com/personal/privacy-security/" target="_blank" rel="noreferrer">Privacy Policy, which is incorporated herein by this reference</a>, explains how we collect, use, and share Visitor information. 
</p>
    <h3>SPAMMING AND SOLICITATION</h3> 

<p>You may not use any information obtained from the site for spamming or solicitation. 
</p>
    <h3>NOTICE OF APPLICABILITY TO CHILDREN </h3>

<p>The site is not intended for children ages 13 and younger.
</p>  </div>
</div> 
<div className="section">
  <div className="section-inner">
    <h2>INTELLECTUAL PROPERTY, TRADEMARKS AND COPYRIGHTS </h2>

    <h3>INTELLECTUAL PROPERTY</h3> 

<p>Intellectual property is protected by copyrights, trademarks, service marks, international treaties or other proprietary rights and laws of the United States and other applicable countries. You agree to abide by all applicable proprietary rights and laws, as well as any additional trademark or copyright notices or restrictions contained in the Terms and Conditions. The Terms and Conditions grant you no right, title or interest in Nationwide Foundation’s intellectual property and create no relationship between you and Nationwide Foundation. You may address all inquiries concerning use of Nationwide Foundation’s intellectual property to: Office of General Counsel, Intellectual Property Department, One Nationwide Plaza, Columbus, OH 43215-2220 (email address: <a href="mailto:trademark@nationwide.com">trademark@nationwide.com</a>). 
</p>
    

    <h3>TRADEMARK NOTICE</h3> 

<p>The trademarks, service marks, slogans, logos, trade dress and other identifiers (“Marks”) displayed on the Site are the property of the Nationwide Foundation or Nationwide Mutual Insurance Company, unless otherwise disclosed. You are prohibited from using any Marks for any purpose (including, but not limited to, use on other materials, in presentations, as domain names or as metatags), without the written permission of Nationwide. 
</p>
    <h3>COPYRIGHT NOTICE</h3> 

<p>© 2021 Nationwide Foundation. All rights reserved. 
</p>
<p>You are prohibited from modifying, copying, distributing, transmitting, displaying, publishing, selling, licensing, creating derivative works or using any Content available on or through the Site for commercial or public purposes, without the prior written permission of Nationwide Foundation. 
</p>
    <h3>DESIGNATED AGENT (DIGITAL MILLENNIUM COPYRIGHT ACT)</h3> 

<p>If you are a copyright owner or an agent thereof of a work protected by a United States copyright that has been linked to, posted, or stored on the Site without authorization and you believe that any Content on the Site infringes upon your copyright(s), you may submit a notification pursuant to the Digital Millennium Copyright Act (“DMCA”) by providing our Copyright Agent, with the following information in writing (see 17 U.S.C 512(c)(3) for further detail): 
</p>
<ul>
<li>  A physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive right that’s allegedly infringed 
</li>
<li>  Identification of the copyrighted work claimed to have been infringed, including a URL (if applicable). If multiple copyrighted works at a single online site are covered by a single notification, please provide a representative list of such works on that site. 
</li>
<li>  Identification of the material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed (or access to which is to be disabled). Please provide information reasonably sufficient to permit the service provider to locate the material. 
</li>
<li>  Information reasonably sufficient to permit the service provider to contact the complaining party, such as an address, telephone number and email address 
</li>
<li>  A statement that you have a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent or the law 
</li>
<li>  A statement that the information in the notification is accurate (under penalty of perjury) and that you are authorized to act on behalf of the owner of an exclusive right that’s allegedly infringed 
</li>
</ul>

    

    <div className="call-out">
      <h3>Please direct copyright infringement notifications to:</h3>
      <address>Marketing and Intellectual Property Legal Team<br />
      1-32-305, One Nationwide Plaza<br />
      Columbus, OH 43215-2220<br />
      <a href="mailto:copyright@nationwide.com">copyright@nationwide.com</a> (the “Copyright Agent”)</address>
      <p>You acknowledge that if you fail to comply with all of these requirements, your DMCA notice may not be valid.</p>

      </div> 



<p>If you believe that your Content that was removed, or to which access was disabled, from the Site is not infringing, or that you have the authorization from the copyright owner, the copyright owner’s agent, or pursuant to the law, to post and use the material in your Content, you may send a counter-notice containing the following information to the Copyright Agent: 
</p>
<ul>    
<li>  Your physical or electronic signature 
</li>
<li>  Identification of the Content that has been removed (or to which access has been disabled) and the location where the Content appeared before it was removed or disabled 
</li>
<li>  A statement that you have a good faith belief that the Content was removed or disabled as a result of a mistake or a misidentification of the Content 
</li>
<li>  Your name, address, telephone number and email address 
</li>
<li>  A statement that you consent to the jurisdiction of the federal court in Columbus, Ohio 
</li>
<li>  A statement that you will accept service of process from the person who provided notification of the alleged infringement 
</li>
</ul>

    

<p>If a counter-notice is received by the Copyright Agent, Nationwide Foundation may send a copy of the counter-notice to the original complaining party informing them that we may replace the removed Content or cease disabling it in 10 business days. Unless the copyright owner files an action seeking a court order against the Content provider, member or user, the removed Content may be replaced (or access to it restored) in 10 to 14 business days. Replacing or restoring Content is at Nationwide Foundation’s sole discretion and could take longer than 14 business days. 
</p>
    <h3>HYPERLINKS</h3> 

<p>From time to time, Nationwide Foundation may authorize linked websites to or from the Site. Linked websites are controlled and operated by third parties, not by Nationwide Foundation. Nationwide Foundation makes no representations, and disclaims all liability as to the Content or material available at those locations, the accuracy of information, and the quality of products or services provided or advertised on linked websites. The fact that Nationwide Foundation authorizes linked websites is not an endorsement, authorization, express or implied sponsorship, or affiliation with respect to linked websites, its owners, sponsors or providers. 
</p>
    <h3>INFORMATION ABOUT THE OPERATION OF THE SITE </h3>

<p>Nationwide Foundation operates the Site from its offices within the United States. Nationwide Foundation does not represent that Content on the Site is appropriate or applicable for use in any other country. Access from certain countries other than the United States may be strictly prohibited. You acknowledge and agree that you are accessing the Site on your own initiative and at your own risk. You are responsible for compliance with all local laws. These Terms and Conditions shall be governed by (and construed in accordance with) the laws of the United States and the State of Ohio. You consent to exclusive jurisdiction and venue in Franklin County, Ohio and waive the defense of forum non conveniens. 
</p>
    <h3>SUBMISSIONS OF UNSOLICITED IDEAS</h3> 

<p>Nationwide Foundation values your feedback. However, we ask that you not submit to us any suggestions, materials, ideas, or creative concepts for new, modified, or different products or services through the Site. Nationwide Foundation makes this request to avoid confusion in the future regarding the origin of the concept, product, or service. 
</p>
<p>If, despite our request that you not send us any creative materials, you still send us creative suggestions, ideas, concepts, or other information (collectively, the “Information”), such Information shall be deemed by Nationwide Foundation to be (and shall remain) the property of Nationwide Foundation. None of the Information shall be subject to any obligation of confidence on the part of Nationwide Foundation. Also, Nationwide Foundation shall not be liable for any use or disclosure of any Information. Without limiting the foregoing, Nationwide Foundation shall exclusively own all rights to the Information of every kind and nature and shall be entitled to unrestricted use of the Information for any purpose whatsoever (commercial or otherwise), without compensation to the provider of the Information. 
</p>
    <h3>VISITOR SUBMISSIONS</h3>

<p>All communications initiated by you (also defined herein as a “Visitor”) and made to or through portions of the Site that permit uploading of questions, comments or other material are hereby considered “Visitor Submissions.” 
</p>
<p>If you submit material intended for display where technically permitted within the Site, you agree to abide by the terms set forth herein. Visitor Submissions will not contain any content that’s illegal, indecent, profane (either directly or indirectly through partially-obscured words, letters, phrases, terminology or the like), threatening, defamatory, derogatory, counter to Nationwide Foundation’s Privacy Policy, or otherwise injurious to Nationwide Foundation or third parties. Visitor Submissions also should not consist of or invoke malicious software code, constitute commercial solicitation (except where pre-approved by Nationwide Foundation), consist primarily of an unsolicited electronic mass mailing, be political in nature, or the like. Where an email address is required to upload Visitor Submissions, you agree to use a valid email address and represent that you are the individual identified by the address. If you wish to submit materials to the Site, you are prohibited from impersonating any other individual or entity, or otherwise mislead as to the origination of the Visitor Submission. 
</p>
<p>Visitor Submissions will not be accepted that infringe in any manner on the copyright, trademark, or other intellectual property rights of any person or entity, or that contain privileged, confidential, proprietary or trade-secret information of any individual or entity, or that may violate the legal rights of any person or entity in any jurisdiction or locale. You may not submit materials that are (or purport to be) the personally identifiable information about others, such as full name, postal address, email address, telephone number, or any other personal attribute which would constitute harassment or an invasion of privacy. 
</p>
<p>Visitor Submissions become the property of Nationwide Foundation. You grant Nationwide Foundation a non-exclusive, royalty-free, perpetual, irrevocable, fully-transferable, sub-licensable and assignable right to use, edit, modify, adapt, translate, edit, and reformat Visitor Submissions as necessary, and for any purpose, anywhere in the world, including into those forms that may constitute advertising. If you submit a Visitor Submission, you are also hereby granting to Nationwide Foundation the non-exclusive right to seek enjoinment and possible damages from any third party who otherwise uses Visitor Submissions in an unauthorized way or through unauthorized reproduction of any Visitor Submission. 
</p>
<p>Visitor Submissions used, displayed, or propagated by Nationwide Foundation or others licensed by Nationwide Foundation to use Visitor Submissions may be attributed to you unless the visitor has expressly requested anonymity. Nationwide Foundation reserves the right to select among the Visitor Submissions for the most appropriate material to be used, subject to Nationwide Foundation’s sole discretion and judgment. Nationwide Foundation otherwise reserves the right to remove Visitor Submissions from the Site pursuant to its discretion and judgment, but maintains no obligation to do so. 
</p>
<p>Visitors also hereby agree to not redistribute or reproduce elsewhere Visitor Submissions in any manner or in any media, including the re-submission or republishing of any Visitor Submission on any other site not affiliated with Nationwide Foundation. 
</p>  </div>
</div>
<div className="section">
  <div className="section-inner">
    <h2>READ MORE INFORMATION ABOUT THIS AGREEMENT</h2> 

    <h3>ENTIRE AGREEMENT</h3>

<p>Except to the extent otherwise set forth in the Terms and Conditions, these Terms and Conditions set forth the entire understanding between Nationwide Foundation and you with respect to your access to (and use of) the Site and its Content. These Terms and Conditions replace all prior or simultaneous understandings about access and use. 
</p>
    <h3>SEVERABILITY</h3> 

<p>If any portion of these Terms and Conditions is found to be unenforceable, the remaining sections of the Terms and Conditions will remain in effect. 
</p>
    <h3>WAIVER</h3> 

<p>Failure by Nationwide Foundation, in any instance, to exercise any of its rights under the Terms and Conditions will not constitute waiver of such right or any other rights under the Terms and Conditions. 
</p>
  </div>
</div>
</div>
  )
}

export default PrivacyPolicy;
