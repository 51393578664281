const pageTitleContent = {
  default: 'Substance Use and Mental Health | Ohio Opioid Education Foundation',
  '/': 'Substance Use and Mental Health | Ohio Opioid Education Alliance',
  '/addiction-mental-health-intro':
    'Challenge What You Know About Addiction Stigmas | Ohio Opioid Education Alliance',
  '/addiction-mental-health-quiz':
    'Beat the Stigma: Addiction and Mental Health Quiz | Ohio Opioid Education Alliance',
  '/game-results':
    'Beat the Stigma: Review Addiction and Mental Health Myths | Ohio Opioid Education Alliance',
  '/about-addiction-mental-health-stigmas':
    'Learn More About Addiction and Mental Health Stigmas | Ohio Opioid Education Alliance',
  '/about-us':
    'Mental Health and Addiction Resources | Ohio Opioid Education Alliance',
  '/privacy-policy':
    'Privacy Policy | Ohio Opioid Education Alliance'
};

export default pageTitleContent;
