import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setGameTimer } from '../../redux/slices/gameSlice';
import ProgressBar from './ProgressBar';

const GameTimer = ({ config, isPaused, handleTimeout }) => {

  const [currentSeconds, setCurrentSeconds] = useState(60);
  const [currentTimerInterval, setCurrentTimerInterval] = useState(0);

  const dispatch = useDispatch();
  const isMobile = window.innerWidth < 1024;
  let secondsText = isMobile ? (<span className="notranslate">s</span>) : "seconds";

  useEffect(() => {
    let timerInterval = null;

    if (!isPaused) {
      timerInterval = setInterval(() => {
        const interval = 100 - (currentSeconds * (100 / 60));

        setCurrentSeconds((currentInterval) => currentInterval - 1);
        setCurrentTimerInterval(interval);
        dispatch(setGameTimer(currentSeconds));

        if (currentSeconds === 0) {
          clearInterval(timerInterval);
          console.log('handleTimeout, timer');
          handleTimeout(currentSeconds);
        }
      }, 1000);
    } else {
      clearInterval(timerInterval);
    }

    return () => {
      clearInterval(timerInterval);
    };
  }, [dispatch, handleTimeout, currentSeconds, isPaused]);

  return (
    <section aria-label="Game timer progress" className={`game-timer-container ${isPaused ? 'paused' : ''}`}>
      <div className="timer-inner">
        <ProgressBar currentInterval={currentTimerInterval} />
        <p>
          <span className="notranslate">{currentSeconds}</span> {secondsText} <span className="sr-only">remaining</span>
        </p>
      </div>
    </section>
  )
}

export default GameTimer;
