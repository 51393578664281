import React from 'react';

const AboutUsPartners = () => {

  return (
    <>
      <div className="section primary-message risk-factor">
        <div className="section-inner">
          <h2>Beat the Stigma Partners</h2>
          <p>
            Beat the Stigma is funded by the Ohio Opioid Education Alliance. The Alliance is comprised of over 100 business, education, nonprofit, civic and government organizations. The Alliance’s objective is to challenge the mindsets of Ohioans, including reframing the approach to drug misuse prevention and eliminating stigmas associated with addiction and mental illness.
          </p>
        </div>
      </div>

      <div className="section three-column">
        <div className="section-inner">
          <div className="three-column-item"><p className="item-title">ADAMH of Franklin County</p></div>
          <div className="three-column-item"><p className="item-title">ADAMHS Board of Cuyahoga County</p></div>
          <div className="three-column-item"><p className="item-title">Alvis, Inc.</p></div>
          <div className="three-column-item"><p className="item-title">American Electric Power</p></div>
          <div className="three-column-item" ><p className="item-title">AMERICAN NITRILE</p></div>
          <div className="three-column-item"><p className="item-title">Appalachian Children's Coalition</p></div>
          <div className="three-column-item"><p className="item-title">Association of Ohio Health Commissioners</p></div>
          <div className="three-column-item"><p className="item-title">AT&T</p></div>
          <div className="three-column-item"><p className="item-title">Bath & Body Works</p></div>
          <div className="three-column-item"><p className="item-title">Battelle</p></div>
          <div className="three-column-item"><p className="item-title">Big Lots</p></div>
          <div className="three-column-item"><p className="item-title">The Buckeye Association of School Administrators (BASA)</p></div>
          <div className="three-column-item"><p className="item-title">The Buckeye Ranch</p></div>
          <div className="three-column-item"><p className="item-title">Capital University</p></div>
          <div className="three-column-item"><p className="item-title">Cardinal Health Foundation</p></div>
          <div className="three-column-item"><p className="item-title">Central Ohio Hospital Council</p></div>
          <div className="three-column-item"><p className="item-title">Central Ohio Funeral Directors Association </p></div>
          <div className="three-column-item"><p className="item-title">Central Ohio Mayors and Managers Association</p></div>
          <div className="three-column-item"><p className="item-title">City of Athens</p></div>
          <div className="three-column-item"><p className="item-title">CITY OF BEXLEY</p></div>
          <div className="three-column-item"><p className="item-title">City of Columbus</p></div>
          <div className="three-column-item"><p className="item-title">City of Grove City</p></div>
          <div className="three-column-item"><p className="item-title">CleanTurn</p></div>
          <div className="three-column-item"><p className="item-title">Cleveland Cavaliers</p></div>
          <div className="three-column-item"><p className="item-title">Columbus Blue Jackets</p></div>
          <div className="three-column-item"><p className="item-title">Columbus Business First</p></div>
          <div className="three-column-item"><p className="item-title">Columbus Chamber of Commerce</p></div>
          <div className="three-column-item"><p className="item-title">Columbus Clippers</p></div>
          <div className="three-column-item"><p className="item-title">Columbus College of Art and Design</p></div>
          <div className="three-column-item"><p className="item-title">COLUMBUS CREW</p></div>
          <div className="three-column-item"><p className="item-title">The Columbus Dispatch</p></div>
          <div className="three-column-item"><p className="item-title">Columbus Foundation</p></div>
          <div className="three-column-item"><p className="item-title">Columbus Metropolitan Library</p></div>
          <div className="three-column-item"><p className="item-title">Columbus Partnership</p></div>
          <div className="three-column-item"><p className="item-title">Columbus Realtors</p></div>
          <div className="three-column-item"><p className="item-title">Columbus Realtors Foundation</p></div>
          <div className="three-column-item"><p className="item-title">Columbus State Community College</p></div>
          <div className="three-column-item"><p className="item-title">CompDrug</p></div>
          <div className="three-column-item"><p className="item-title">Crane Group</p></div>
          <div className="three-column-item"><p className="item-title">Crawford County Public Health</p></div>
          <div className="three-column-item"><p className="item-title">Donatos Pizza</p></div>
          <div className="three-column-item"><p className="item-title">Drug Free Clubs of America</p></div>
          <div className="three-column-item"><p className="item-title">Education Service Center of Central Ohio</p></div>
          <div className="three-column-item"><p className="item-title">Encova Insurance Group</p></div>
          <div className="three-column-item"><p className="item-title">Faith Ministries</p></div>
          <div className="three-column-item"><p className="item-title">Family Missionary Baptist Church</p></div>
          <div className="three-column-item"><p className="item-title">First Congregational Church</p></div>
          <div className="three-column-item"><p className="item-title">Franklin County Board of Commissioners</p></div>
          <div className="three-column-item"><p className="item-title">Franklin County Board of Developmental Disabilities</p></div>
          <div className="three-column-item"><p className="item-title">Franklin County Children Services</p></div>
          <div className="three-column-item"><p className="item-title">Franklin County Public Health</p></div>
          <div className="three-column-item"><p className="item-title">Foundation for Appalachian Ohio</p></div>
          <div className="three-column-item"><p className="item-title">G&J Pepsi Cola Bottlers, Inc.</p></div>
          <div className="three-column-item"><p className="item-title">GRIEF</p></div>
          <div className="three-column-item"><p className="item-title">Hancock Public Health</p></div>
          <div className="three-column-item"><p className="item-title">HealthPath Foundation of Ohio</p></div>
          <div className="three-column-item"><p className="item-title">Honda</p></div>
          <div className="three-column-item"><p className="item-title">Human Service Chamber </p></div>
          <div className="three-column-item"><p className="item-title">Huntington </p></div>
          <div className="three-column-item"><p className="item-title">IGS Energy</p></div>
          <div className="three-column-item"><p className="item-title">Jefferson County Prevention and Recovery Board</p></div>
          <div className="three-column-item"><p className="item-title">JPMorgan Chase & Co.</p></div>
          <div className="three-column-item"><p className="item-title">LAKE COUNTY CAPTAINS</p></div>
          <div className="three-column-item"><p className="item-title">Law Enforcement Foundation</p></div>
          <div className="three-column-item"><p className="item-title">Life Town Columbus</p></div>
          <div className="three-column-item"><p className="item-title">Maryhaven</p></div>
          <div className="three-column-item"><p className="item-title">McGohan Brabender</p></div>
          <div className="three-column-item"><p className="item-title">Medina County Health Department</p></div>
          <div className="three-column-item"><p className="item-title">Mental Health & Addiction Advocacy Coalition</p></div>
          <div className="three-column-item"><p className="item-title">Mt. Carmel Health</p></div>
          <div className="three-column-item"><p className="item-title">NAMI Franklin County</p></div>
          <div className="three-column-item"><p className="item-title">NAMI OHIO</p></div>
          <div className="three-column-item"><p className="item-title">National Safety Council</p></div>
          <div className="three-column-item"><p className="item-title">Nationwide Children's Hospital</p></div>
          <div className="three-column-item"><p className="item-title">Nationwide Foundation</p></div>
          <div className="three-column-item"><p className="item-title">NFIB</p></div>
          <div className="three-column-item"><p className="item-title">NiSource and Columbia Gas</p></div>
          <div className="three-column-item"><p className="item-title">Ohio Association of Broadcasters</p></div>
          <div className="three-column-item"><p className="item-title">Ohio Association of Chiefs of Police</p></div>
          <div className="three-column-item"><p className="item-title">Ohio Association of County Behavioral Health Authorities</p></div>
          <div className="three-column-item"><p className="item-title">Ohio Association of Health Plans</p></div>
          <div className="three-column-item"><p className="item-title">Ohio Association of Secondary Schools Administrators (OASSA)</p></div>
          <div className="three-column-item"><p className="item-title">Ohio Business Roundtable</p></div>
          <div className="three-column-item"><p className="item-title">Ohio Chamber of Commerce</p></div>
          <div className="three-column-item"><p className="item-title">Ohio Children's Alliance</p></div>
          <div className="three-column-item"><p className="item-title">The Ohio Council of Behavioral Health & Family Services Providers</p></div>
          <div className="three-column-item"><p className="item-title">The Ohio Council of Retail Merchants</p></div>
          <div className="three-column-item"><p className="item-title">Ohio Dental Association</p></div>
          <div className="three-column-item"><p className="item-title">Ohio Department of Mental Health and Addiction Services </p></div>
          <div className="three-column-item"><p className="item-title">Ohio Dominican University</p></div>
          <div className="three-column-item"><p className="item-title">Ohio ESC Association</p></div>
          <div className="three-column-item"><p className="item-title">Ohio Farm Bureau</p></div>
          <div className="three-column-item"><p className="item-title">Ohio Foundation of Independent Colleges</p></div>
          <div className="three-column-item"><p className="item-title">Ohio Funeral Directors Association</p></div>
          <div className="three-column-item"><p className="item-title">OhioHealth</p></div>
          <div className="three-column-item"><p className="item-title">Ohio High School Athletic Association</p></div>
          <div className="three-column-item"><p className="item-title">Ohio Hospital Association </p></div>
          <div className="three-column-item"><p className="item-title">Ohio Hotel & Lodging Association</p></div>
          <div className="three-column-item"><p className="item-title">Ohio Latino Affairs Commission</p></div>
          <div className="three-column-item"><p className="item-title">Ohio Library Council</p></div>
          <div className="three-column-item"><p className="item-title">Ohio Manufacturer's Association</p></div>
          <div className="three-column-item"><p className="item-title">Ohio Mayors Alliance</p></div>
          <div className="three-column-item"><p className="item-title">Ohio Orthopaedic Society</p></div>
          <div className="three-column-item"><p className="item-title">Ohio Pharmacists Association </p></div>
          <div className="three-column-item"><p className="item-title">Ohio Psychological Association</p></div>
          <div className="three-column-item"><p className="item-title">Ohio Realtors</p></div>
          <div className="three-column-item"><p className="item-title">Ohio Restaurant Association</p></div>
          <div className="three-column-item"><p className="item-title">Ohio State Medical Association</p></div>
          <div className="three-column-item"><p className="item-title">The Ohio School Boards Association (OSBA)</p></div>
          <div className="three-column-item"><p className="item-title">The Ohio State University</p></div>
          <div className="three-column-item"><p className="item-title">OHIO UNIVERSITY</p></div>
          <div className="three-column-item"><p className="item-title">Ohio Wesleyan University</p></div>
          <div className="three-column-item"><p className="item-title">One Church</p></div>
          <div className="three-column-item"><p className="item-title">OneMain Financial</p></div>
          <div className="three-column-item"><p className="item-title">OpenDoor-Columbus</p></div>
          <div className="three-column-item"><p className="item-title">Osteopathic Heritage Foundations</p></div>
          <div className="three-column-item"><p className="item-title">Otterbein University</p></div>
          <div className="three-column-item"><p className="item-title">Philanthropy Ohio</p></div>
          <div className="three-column-item"><p className="item-title">PNC Financial Service Group Inc.</p></div>
          <div className="three-column-item"><p className="item-title">Prevention Action Alliance</p></div>
          <div className="three-column-item"><p className="item-title">The Reid Foundation</p></div>
          <div className="three-column-item"><p className="item-title">Safelite</p></div>
          <div className="three-column-item"><p className="item-title">Sandusky County Public Health</p></div>
          <div className="three-column-item"><p className="item-title">Schoedinger Funeral and Cremation</p></div>
          <div className="three-column-item"><p className="item-title">Stark County Health Department</p></div>
          <div className="three-column-item"><p className="item-title">Stark County Mental Health & Addiction Recovery</p></div>
          <div className="three-column-item"><p className="item-title">State Auto</p></div>
          <div className="three-column-item"><p className="item-title">Summit County Public Health</p></div>
          <div className="three-column-item"><p className="item-title">Sunday Creek Horizons</p></div>
          <div className="three-column-item"><p className="item-title">SWACO</p></div>
          <div className="three-column-item"><p className="item-title">UnitedHealthcare</p></div>
          <div className="three-column-item"><p className="item-title">United Way of Central Ohio</p></div>
          <div className="three-column-item"><p className="item-title">United Way of Clark, Champaign and Madison Counties</p></div>
          <div className="three-column-item"><p className="item-title">United Way of Erie County, Inc.</p></div>
          <div className="three-column-item"><p className="item-title">United Way of Fairfield County</p></div>
          <div className="three-column-item"><p className="item-title">United Way of the Greater Dayton Area</p></div>
          <div className="three-column-item"><p className="item-title">United Way of Muskingum, Perry and Morgan Counties</p></div>
          <div className="three-column-item"><p className="item-title">United Way of Sandusky County</p></div>
          <div className="three-column-item"><p className="item-title">WBNS-10TV</p></div>
          <div className="three-column-item"><p className="item-title">White Castle Systems</p></div>
          <div className="three-column-item"><p className="item-title">Worthington Industries</p></div>
        </div>


      </div>
    </>
  );
};

export default AboutUsPartners;
