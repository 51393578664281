import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { selectUserAnswers, selectGameTimer, selectResultsContent } from '../../redux/slices/gameSlice';
import ResultsContent from './ResultsContent';
import './results.scss';

const Results = () => {
  const secondsLeft = useSelector(selectGameTimer);
  const answers = useSelector(selectUserAnswers);
  const content = useSelector(selectResultsContent);

  useEffect(() => {
    window.gtag('event', 'conversion', {
      'allow_custom_scripts': true,
      'send_to': 'DC-8663302/adamh-rt/bts-gcpv+unique'
    });

  })
  return (
    <div className="results-container">

      {content !== null ? <ResultsContent answers={answers} secondsLeft={secondsLeft} content={content} /> : <Redirect to="/" />}

    </div>
  )
}

export default Results;
