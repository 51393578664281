import React from 'react';
import { Link } from 'react-router-dom';
import { FooterContent } from '../../content/quiz-content';
import './footer.scss';

const Footer = () => {

  return (
    <footer role="contentinfo">
      <p className="sr-only">Ohio Opioid Education Alliance</p>
      <hr className="hr-left" />
      <div className="footer-inner">
        <ul>
          {FooterContent.footerLinks.map((link, idx) => (
            <li key={`${link.url}-${idx}`}>
              <a href={link.url} className={link.className} target="_blank" rel="noreferrer">{link.text}</a>
            </li>
          ))}
        </ul>

        <div className="footer-links">
          <Link className="standard-link" to="/privacy-policy">Privacy Policy</Link> <span className="pipe">|</span> <a href="mailto: contact@OpioidAlliance.org" target="_blank" rel="noreferrer" className="standard-link">Contact Us</a>
        </div>
      </div>
      <hr className="hr-right" />
    </footer>
  );
}
export default Footer;
