import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from "history";
import { HelmetProvider } from 'react-helmet-async';
import TagManager from 'react-gtm-module'
import { gtmManager } from './utils/gtmManager';
import './sass/main.scss';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import LandingPage from './components/LandingPage/LandingPage';
import GameIntro from './components/GameIntro/GameIntro';
import Quiz from './components/Quiz/Quiz';
import Results from './components/Results/Results';
import MoreAboutStigmas from './components/MoreAboutStigmas/MoreAboutStigmas';
import AboutUs from './components/AboutUs/AboutUs';
import PageNotFound from './components/PageNotFound/PageNotFound';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';

import ScrollToTop from './utils/ScrollToTop';

const App = () => {

  const history = createBrowserHistory();
  const helmetContext = {};
  const location = window.location.href;
  const gtm = gtmManager(location);

  useEffect(() => {
    TagManager.initialize({
      gtmId: gtm.id,
      auth: gtm.config.auth,
      preview: gtm.config.env
    })
  }, [gtm]);

  return (
    <div className="quiz-app" id="beat-stigma">
      <Header />
      <main id="main">
        <ScrollToTop>
          <HelmetProvider context={helmetContext}>
            <Switch>
              <Route exact path="/">
                <LandingPage />
              </Route>
              <Route path="/addiction-mental-health-intro">
                <GameIntro startLink="/addiction-mental-health-quiz" />
              </Route>
              <Route history={history} path="/addiction-mental-health-quiz">
                <Quiz />
              </Route>
              <Route history={history} path="/game-results">
                <Results />
              </Route>
              <Route history={history} path="/about-addiction-mental-health-stigmas">
                <MoreAboutStigmas />
              </Route>
              <Route history={history} path="/about-us">
                <AboutUs />
              </Route>
              <Route history={history} path="/privacy-policy">
                <PrivacyPolicy />
              </Route>
              <Route path="*">
                <PageNotFound />
              </Route>
            </Switch>
          </HelmetProvider>
        </ScrollToTop>
      </main>
      <Footer />
    </div>
  );
}

export default App;
