import React from "react";
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Parser from 'html-react-parser';
import SocialButtons from "../SocialButtons/SocialButtons";
import HeadTagManager from '../../utils/HeadTagManager';
import { selectGameTimerOff } from '../../redux/slices/gameSlice';
import { ResultsContent as Content } from "../../content/quiz-content";

const ResultsContent = ({ content, answers, secondsLeft }) => {
  const gameTimerOff = useSelector(selectGameTimerOff);

  const correctMessage = !gameTimerOff ? `You got ${content.correctCount} out of ${content.questionCount} questions correct in ${60 - secondsLeft} seconds!` : `You got ${content.correctCount} out of ${content.questionCount} questions correct!`;

  const socialMessage = !gameTimerOff ? `I got ${content.correctCount} out of ${content.questionCount} questions correct in ${60 - secondsLeft} seconds on the Beat The Stigma quiz!` : `I got ${content.correctCount} out of ${content.questionCount} questions correct on the Beat The Stigma quiz!`;

  return (
    <>
      <HeadTagManager title={Content.headTag.title} metaTags={Content.headTag.metaTags} />
      <section className={`results-main ${content.tier > 3 ? 'low-score' : ''}`}>
        <div className="results-inner">
          <span className="correct-count">{correctMessage}</span>
          <h1>{content.hero.header}</h1>
          <p>{content.hero.bodyCopy}</p>

          <Link to="/addiction-mental-health-intro" className="button button-replay">Try the game again</Link>
          <Link to="/about-addiction-mental-health-stigmas" className="standard-link">Learn how you can help beat the Stigma</Link>

          <SocialButtons hashtag="BeatTheStigma" string={socialMessage} url="https://beatthestigma.org" />
        </div>
      </section>

      <section className="results-answers">
        <div className="section-inner">
          <h2>Your Answers</h2>
          {answers.map((answer, idx) => (
            <div className="user-answer" key={`answer-${idx}`}>
              <div className="question-answer-container">
                <div className="question-answer">
                  <h4>Question {idx + 1}</h4>
                  <p>{Parser(answer.question)}</p>
                </div>
                <div className="your-answer">
                  <p className="result-label">Your answer</p>
                  <span className={answer.userCorrect ? "correct" : "incorrect"}>
                    {typeof answer.userAnswer === "string"
                      ? Parser(answer.userAnswer)
                      : Parser(answer.answers[answer.userAnswer])
                    }
                  </span>
                </div>
              </div>
              <div className="correct-answer">
                <p className="result-label">Correct Answer</p>
                <span className="correct">{answer.answers[answer.answerIndex]}</span>
                {Parser(answer.additionalInfo)}
                <a href={answer.learnMore} rel="noreferrer" target="_blank">Learn More</a>
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  )
}

export default ResultsContent;
