const inertManager = {
  /**
   * set focusable els outside of the modal to inert
   * @param modalContainerEl the modal container el
   * @param inertContainerEls container els to look for focusable els
   * @returns {*[]} the affected els, returned to the parent fn to be used as basis for resetting to normal
   */
  setInert: ({ modalContainerEl, inertContainerEls }) => {
    const elsToSetInert = [
      ...document.querySelectorAll(
        'a:not([disabled]), button:not([disabled]), input:not([disabled]), textarea:not([disabled]), select:not([disabled]), iframe'
      ),
      ...document.querySelectorAll('[tabindex]')
    ];

    // store original aria-hidden attr
    [...inertContainerEls].forEach(el => {
      if (el.getAttribute('aria-hidden') !== null) {
        el.setAttribute(
          'data-temp-aria-hidden',
          el.getAttribute('aria-hidden')
        );
      }
      el.setAttribute('aria-hidden', true);
    });

    // store original tabindex attr
    elsToSetInert.forEach(el => {
      if (!modalContainerEl.contains(el)) {
        if (el.getAttribute('tabindex') !== null) {
          el.setAttribute('data-temp-tabindex', el.getAttribute('tabindex'));
        }
        el.setAttribute('tabindex', '-1');
      }
    });
    return elsToSetInert;
  },

  /**
   * reset inert els to original state
   * @param modalContainerEl the modal container el
   * @param inertContainerEls container els to look for focusable els
   * @param inertEls the els that were made inert
   */
  unsetInert: ({ modalContainerEl, inertContainerEls, inertEls }) => {

    // reset aria-hidden to original value
    [...inertContainerEls].forEach(el => {
      if (el.getAttribute('data-temp-aria-hidden') !== null) {
        el.setAttribute('aria-hidden', el.getAttribute('data-temp-aria-hidden'));
        el.removeAttribute('data-temp-aria-hidden');
      } else {
        el.removeAttribute('aria-hidden');
      }
    });

    // reset tabindex to original value
    inertEls.forEach(el => {
      if (!modalContainerEl.contains(el)) {
        if (el.getAttribute('data-temp-tabindex') !== null) {
          el.setAttribute('tabindex', el.getAttribute('data-temp-tabindex'));
          el.removeAttribute('data-temp-tabindex');
        } else {
          el.removeAttribute('tabindex');
        }
      }
    });
  }
};

export default inertManager;
