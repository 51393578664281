import React from "react";
import Parser from 'html-react-parser';
import MoreInfo from '../MoreInfo/MoreInfo';
import TwoColumnPromo from '../TwoColumnPromo/TwoColumnPromo';

const HowToHelp = ({ content }) => {

  return (
    <>
      <div className="section primary-message how-to-help">
        <div className="section-inner">
          <h2>{content.primaryMessage.header}</h2>
          {Parser(content.primaryMessage.copy)}
        </div>
      </div>

      <MoreInfo content={content.moreInfo} />

      <div className="section two-column-content bg-white">
        <div className="section-inner">
          <div className="column-span-5 shift-1">
            <h3>Remember That<br />the Person Is<br />Not the Disease</h3>
          </div>
          <div className="column-span-5">
            <p>Don't define someone by their diagnosis. They're not an alcoholic or a schizophrenic. They're a person, coping with alcohol use disorder or with schizophrenia. Focus on the person, not the disease, when you're talking about them.</p>
          </div>
        </div>
      </div>

      <div className="section promo-white bg-primary">
        <div className="section-inner">
          <div className="content">
            <h2>Share the Risks of Mental Health and Substance Use With Your Kids</h2>
            <p style={{ "width": "75%", "margin": "0 auto", "textAlign": "center" }}>If you have family members who have mental health or substance use disorders, your kids may be more likely to have those same disorders. Start the conversation before they're teenagers, and keep it simple.</p>
          </div>
          <TwoColumnPromo content={content.learnMorePromo} />
        </div>
      </div>

      <div className="section words-matter two-column-content bg-white">
        <div className="section-inner span-10 row">
          <div className="header-centered">
            <h2>Think About What You Say</h2>
            <p>Words matter. Using negative words about mental illness and substance use problems can keep stigmas alive. Whether you're talking to or about someone who is dealing with mental illness or substance use problems, here's how to show your support.
            </p>
          </div>
        </div>
        <div className="section-inner span-10 row">
          <div className="column col-left" role="group">
            <p className="wordlist-label incorrect">Instead of</p>
            <ul>
              <li>Just push through it</li>
              <li>You don't have it that bad</li>
            </ul>
            <ul>
              <li>Everyone gets sad or anxious</li>
            </ul>
          </div>
          <div className="column col-right" role="group">
            <p className="wordlist-label correct">Try:</p>
            <ul>
              <li>I'm here for you</li>
              <li>You're not alone</li>
            </ul>
            <ul>
              <li>Talking to someone could help</li>
            </ul>
          </div>
        </div>
        <div className="section-inner span-10 row">
          <div className="column col-left" role="group">
            <p className="wordlist-label incorrect">Instead of</p>
            <ul>
              <li>Abuser</li>
              <li>Addict</li>
            </ul>
            <ul>
              <li>Alcoholic</li>
              <li>Junkie</li>
            </ul>
          </div>
          <div className="column col-right" role="group">
            <p className="wordlist-label correct">Try:</p>
            <p>Person with a substance use disorder or person with a drug/alcohol use disorder</p>
          </div>
        </div>
        <div className="section-inner span-10 row">
          <div className="column col-left" role="group">
            <p className="wordlist-label incorrect">Instead of</p>
            <ul>
              <li>Clean</li>
            </ul>

          </div>
          <div className="column col-right" role="group">
            <p className="wordlist-label correct">Try:</p>
            <p>In recovery</p>
          </div>
        </div>
        <div className="section-inner span-10 row">
          <div className="column col-left" role="group">
            <p className="wordlist-label incorrect">Instead of</p>
            <ul>
              <li>Crazy</li>
              <li>Insane</li>
            </ul>
            <ul>
              <li>Psycho</li>
            </ul>
          </div>
          <div className="column col-right" role="group">
            <p className="wordlist-label correct">Try:</p>
            <p>Person with a mental health disorder or person living with depression/anxiety/schizophrenia</p>
          </div>
        </div>
      </div>

      <div className="section bg-white centered">
        <div className="section-inner border-blue">
          <h2>Learn more about how words can hurt</h2>
          <a href="https://www.drugabuse.gov/nidamed-medical-health-professionals/health-professions-education/words-matter-terms-to-use-avoid-when-talking-about-addiction " target="_blank" rel="noreferrer" className="button light-blue">Visit Drugabuse.gov</a>
        </div>
      </div>

    </>
  )
}

export default HowToHelp;
