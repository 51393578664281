import React, { useState, useEffect } from 'react';
import Parser from 'html-react-parser';

const Answer = ({
  answer,
  index,
  handleAnswer,
  isPaused,
  isIncorrect,
  isSkipped,
  correctAnswer
}) => {
  const [buttonClasses, setButtonClasses] = useState('answer-button');
  const [isSelected, setIsSelected] = useState(false);
  const [answerFlag, setAnswerFlag] = useState('');

  const handleButtonClick = () => {
    if (isPaused) return;
    setIsSelected(true);
    handleAnswer(index);
  };

  useEffect(() => {
    if (isPaused && !isSkipped) {
      if (isSelected && index === correctAnswer) {
        setButtonClasses('answer-button correct');
        setAnswerFlag(' is the correct answer');
      } else if (isSelected && index !== correctAnswer) {
        setButtonClasses('answer-button incorrect');
        setAnswerFlag(' is incorrect');
      }

      if (!isSelected && index === correctAnswer) {
        setButtonClasses('answer-button correct');
        setAnswerFlag(' is the correct answer');
      } else if (!isSelected && index !== correctAnswer) {
        setButtonClasses('answer-button');
        setAnswerFlag('');
      }
    } else {
      setButtonClasses('answer-button');
    }
  }, [isPaused, correctAnswer, index, isSelected, isSkipped]);

  return (
    <button
      aria-disabled={isPaused}
      className={`answer-button ${buttonClasses}`}
      onClick={handleButtonClick}
    >
      {Parser(answer)}
      <span className="sr-only">
        {(isSelected || index === correctAnswer) && (
          answerFlag
        )}
      </span>
    </button>
  );
};

export default Answer;
