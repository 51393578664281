import React from 'react';

const ProgressBar = ({ currentInterval }) => {

  return (
    <div className="progress-bar">
      <div className="progress-bar-inner" style={{width: (100 - currentInterval) + '%'}} />
    </div>
  )
}

export default ProgressBar;
