import React from "react";
import RiskFactor from './RiskFactor';
import HowToHelp from './HowToHelp';
import Parser from 'html-react-parser';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import HeadTagManager from '../../utils/HeadTagManager';
import { moreAboutContent } from '../../content/quiz-content';

const MoreAboutStigmas = () => {

  return (
    <>
      <HeadTagManager
        title={moreAboutContent.headTag.title}
        metaTags={moreAboutContent.headTag.metaTags} />
      <div className="tabbed-content">
        <div className="section tabbed-content-hero">
          <div className="section-inner">
            <h1>{Parser(moreAboutContent.textHero.header)}</h1>
            <p>{moreAboutContent.textHero.copy}</p>
          </div>
        </div>

        <Tabs>
          <div className="section tabs-container">
            <div className="section-inner">
              <TabList>
                <Tab>Risk Factors</Tab>
                <Tab>How To Help</Tab>
              </TabList>
            </div>
          </div>
          <TabPanel>
            <RiskFactor content={moreAboutContent.riskFactor} />
          </TabPanel>
          <TabPanel>
            <HowToHelp content={moreAboutContent.howToHelp} />
          </TabPanel>
        </Tabs>

      </div>
    </>
  )
}

export default MoreAboutStigmas;
