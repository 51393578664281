import React from "react";
import Parser from 'html-react-parser';
import MoreInfo from '../MoreInfo/MoreInfo';
// import TwoColumnPromo from "../TwoColumnPromo/TwoColumnPromo";

const RiskFactor = ({ content }) => {

  return (
    <>
      <div className="section primary-message risk-factor">
        <div className="section-inner">
          <h2>{content.primaryMessage.header}</h2>
          {Parser(content.primaryMessage.copy)}
        </div>
      </div>

      <MoreInfo content={content.moreInfo} />

      <div className="section two-column-promo bg-white">
        <div className="section-inner">
          <h2>Know Your Risk</h2>
          <div className="promos">
            <div className="promo-box">
              <h3>Take the screening </h3>
              <a href="https://screening.mentalhealthscreening.org/recoveryisbeautiful" target="_blank" rel="noreferrer" className="button light-blue long-string">Visit mentalhealthscreening.org</a>
            </div>

            <div className="promo-box">
              <h3>Ways to talk to your kids </h3>
              <a href="https://drugfree.org/article/when-addiction-is-in-your-family-tree/" target="_blank" rel="noreferrer" className="button light-blue">Visit drugfree.org</a>
            </div>
          </div>
        </div>
      </div>

      <MoreInfo content={content.moreInfoTwo} alternateLayout={true} />

      <div className="section two-column-promo bg-gray" style={{ "paddingTop": "0" }}>
        <div className="section-inner" style={{ "borderTop": "1px solid #99d8ff", "padding": "7rem 0" }}>
          <h2>Find support</h2>
          <div className="promos">
            <div className="promo-box">
              <h3>For mental health</h3>
              <a href="https://findtreatment.samhsa.gov" target="_blank" rel="noreferrer" className="button light-blue">Visit SAMHSA.gov</a>
              <a href="https://mha.ohio.gov/get-help/get-help-now/01-stress-first-aid" target="_blank" rel="noreferrer" className="button light-blue">VISIT MHA.OHIO.GOV</a>
            </div>

            <div className="promo-box">
              <h3>For addiction</h3>
              <a href="https://findtreatment.gov/" target="_blank" rel="noreferrer" className="button light-blue">Visit findtreatment.gov</a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default RiskFactor;
