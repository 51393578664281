import React from 'react';
import {
  FacebookShareButton,
  TwitterShareButton

} from "react-share";
import './social-buttons.scss';

const SocialButtons = ({ hashtag, string, url }) => {

  return (
    <div className="social-buttons">
      <h2>Share</h2>
      <ul>
        <li>
          <FacebookShareButton
            aria-label="Facebook"
            url={url}
            quote={string}
            hashtag={`#${hashtag}`}>
              <span className="social-facebook" />
          </FacebookShareButton>
        </li>
        <li>
          <TwitterShareButton
            aria-label="Twitter"
            url={url}
            title={string}
            hashtags={[hashtag]}>
            <span className="social-twitter" />
          </TwitterShareButton>
        </li>
      </ul>
    </div>
  )
}

export default SocialButtons;
