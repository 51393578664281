import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Parser from 'html-react-parser'
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import HeadTagManager from '../../utils/HeadTagManager';
import { IntroContent } from '../../content/quiz-content';
import { setGameTimerOff, resetUserAnswers } from '../../redux/slices/gameSlice';
import './quiz-intro.scss';
const GameIntro = ({ startLink }) => {

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(setGameTimerOff(false));
    dispatch(resetUserAnswers([]));

    window.gtag('event', 'conversion', {
      'allow_custom_scripts': true,
      'send_to': 'DC-8663302/adamh-rt/bts-gspv+standard'
    });

  }, [dispatch]);

  const handleGameTimer = (e) => {
    e.preventDefault();
    dispatch(setGameTimerOff(true));
    history.push(startLink);
  }

  return (
    <div className="quiz-intro">
      <span className="sr-only">Serious-faced host facing camera with contestant in background</span>
      <HeadTagManager
        title={IntroContent.headTag.title}
        metaTags={IntroContent.headTag.metaTags} />
      <div>
        <div className="section-inner">
          <div className="intro-stage">
            <div>
              <h1>{IntroContent.header}</h1>
              {Parser(IntroContent.bodyCopy)}

              <Link className="button glow-blue intro" to={startLink}>{IntroContent.startLinkText}</Link>

              <div className="no-timer">
                <Link to={startLink} onClick={handleGameTimer} className="standard-link">Play the game without a timer</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GameIntro;
