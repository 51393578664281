import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  allQuestions: [],
  userAnswers: [],
  gameTimer: 60,
  resultsContent: null,
  gameTimerOff: false
};

export const gameSlice = createSlice({
  name: 'game',
  initialState,

  reducers: {
    setAllQuestions: (state, action) => {
      state.allQuestions = action.payload;
    },

    setUserAnswers: (state, action) => {
      state.userAnswers.push(action.payload);
    },

    resetUserAnswers: (state, action) => {
      state.userAnswers = action.payload;
    },

    setGameTimer: (state, action) => {
      state.gameTimer = action.payload;
    },

    setResultsContent: (state, action) => {
      state.resultsContent = action.payload;
    },

    setGameTimerOff: (state, action) => {
      state.gameTimerOff = action.payload;
    },
  },
});

export const { 
  setAllQuestions, 
  setUserAnswers,
  resetUserAnswers, 
  setGameTimer, 
  setResultsContent,
  setGameTimerOff 
} = gameSlice.actions;

export const selectAllQuestions = (state) => state.game.allQuestions;
export const selectGameTimer = (state) => state.game.gameTimer;
export const selectUserAnswers = (state) => state.game.userAnswers;
export const selectResultsContent = (state) => state.game.resultsContent;
export const selectGameTimerOff = (state) => state.game.gameTimerOff;

export default gameSlice.reducer;
