import React from 'react';
import Parser from 'html-react-parser';
import './more-info.scss';

const MoreInfo = ({ content, alternateLayout }) => {
  let alternateClass = "";

  if (alternateLayout) {
    if (content.length === 1) {
      alternateClass = "alternate-layout";
    }
  }

  return (
    <div className={`${alternateClass} more-info`}>
      {content.map((info) => (
        <div className="section-inner-wide" key={info.title}>

          <div className="info-container">
            <div className="info-item">
              <h2>{Parser(info.title)}</h2>
              <hr />
              {Parser(info.content)}

              {info.secondaryLink && (
                <>
                  <h4>{info.secondaryLink.header}</h4>
                  <a href={info.secondaryLink.url} target="_blank" rel="noreferrer">{info.secondaryLink.text}</a>
                </>
              )}
            </div>
            <div className={`pull-quote-box ${info.iconClasses}`}>
              <span className="sr-only">{info.iconAltText || ''}</span>
              {Parser(info.pull)}
              {info.pullButton && (
                <a className="button-more-info" href={info.pullButton.url} target="_blank" rel="noreferrer">{info.pullButton.text}</a>
              )}
              {info.secondaryPullButton && (
                <a className="button-more-info secondary" href={info.secondaryPullButton.url} target="_blank" rel="noreferrer">{info.secondaryPullButton.text}</a>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default MoreInfo;
