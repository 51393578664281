/*eslint-disable react-hooks/exhaustive-deps*/
import React, { useState, useRef, useLayoutEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useMediaQuery, usePreviousValue } from 'beautiful-react-hooks';

import { HeaderContent } from '../../content/quiz-content';
import pageTitleContent from '../../content/page-title-content';
import Logo from '../../assets/images/header-logo-beatthestigma.svg';
import inertManager from '../../utils/inertManager';

// selectors in which focusable elements can be found, excluding the mobile nav
const inertSelectors = 'main, footer, nav > .logo, .skip-link';

const Header = () => {
  const accessibleTitle = useRef();
  const mobileNavToggleOpen = useRef();
  const mobileNavToggleClose = useRef();
  const navLinks = useRef();

  const { pathname } = useLocation();
  const prevPathname = usePreviousValue(pathname);

  const [title, setTitle] = useState(pageTitleContent['/'] || '');
  const [inertEls, setInertEls] = useState([]); // focusable els outside of the modal
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  const isDesktop = useMediaQuery('(min-width: 1024px)');

  // find all focusable els outside of the modal and make them inert
  const setFocusTrap = () => {
    setInertEls(
      inertManager.setInert({
        modalContainerEl: navLinks.current,
        inertContainerEls: [...document.querySelectorAll(inertSelectors)]
      })
    );
  };

  // reset to normal focus
  const unsetFocusTrap = () => {
    inertManager.unsetInert({
      modalContainerEl: navLinks.current,
      inertContainerEls: [...document.querySelectorAll(inertSelectors)],
      inertEls
    });
  };

  // on mobile nav state change, trap or reset focus
  useLayoutEffect(() => {
    if (isMobileNavOpen && !isDesktop) {
      setFocusTrap();
      mobileNavToggleClose.current.focus();
    } else if (!isMobileNavOpen) {
      unsetFocusTrap();
      if (!isDesktop && pathname === prevPathname) {
        mobileNavToggleOpen.current.focus();
      }
    }
  }, [isMobileNavOpen]);

  // on route change, set title, focus, reset mobile nav
  useLayoutEffect(() => {
    setIsMobileNavOpen(false);
    accessibleTitle.current.focus();
    setTitle(
      currentTitle =>
        pageTitleContent[pathname] || pageTitleContent.default || currentTitle
    );
  }, [pathname]);

  // make sure mobile nav state is reset when transitioning to desktop
  useLayoutEffect(() => {
    if (isDesktop && isMobileNavOpen) {
      setIsMobileNavOpen(false);
      document.querySelector('body').focus();
    }
  }, [isDesktop]);

  return (
    <header role="banner" className="l-navigation">
      <p
        className="sr-only-force"
        ref={accessibleTitle}
        tabIndex="-1"
        aria-live="assertive"
      >
        {title}
      </p>
      <a className="skip-link sr-only" href="#main">
        Skip to main content
      </a>
      <nav className="inner-header">
        <div className="logo">
          <NavLink to="/">
            <img src={Logo} alt="Beat the Stigma" />
          </NavLink>
        </div>
        <div
          aria-hidden={!isDesktop && !isMobileNavOpen}
          ref={navLinks}
          id="nav-links"
          role="group"
          className={`nav-links ${isMobileNavOpen && !isDesktop ? 'mobile-nav-open' : ''
            }`}
        >
          <button
            aria-hidden={isDesktop || !isMobileNavOpen}
            tabIndex={isMobileNavOpen ? '' : '-1'}
            ref={mobileNavToggleClose}
            aria-controls="nav-links"
            aria-label="Close navigation"
            className={`mobile-nav-toggle-close ${isDesktop || !isMobileNavOpen ? 'sr-only' : ''
              }`}
            onClick={() => setIsMobileNavOpen(false)}
          />
          <ul>
            {HeaderContent.navLinks.map((link, idx) => (
              <li key={idx} onClick={() => setIsMobileNavOpen(false)}>
                <NavLink
                  tabIndex={isDesktop || isMobileNavOpen ? '' : '-1'}
                  to={link.link}
                >
                  {link.text}
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
        <button
          aria-hidden={isDesktop || !isMobileNavOpen}
          tabIndex={!isMobileNavOpen ? '' : '-1'}
          ref={mobileNavToggleOpen}
          aria-controls="nav-links"
          aria-label="Open navigation"
          className={`mobile-nav-toggle-open ${isDesktop || isMobileNavOpen ? 'sr-only' : ''
            }`}
          onClick={() => setIsMobileNavOpen(true)}
        />
      </nav>
    </header>
  );
};

export default Header;
