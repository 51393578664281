import pageTitleContent from './page-title-content';
import videoSchema from './videoSchema';

export const gtmConfig = {
  id: "GTM-WLB2PT2",
  stage: {
    env: "env-3",
    auth: "Rl2E3ypAxF38w4r_OnlLDA"
  },
  prod: {
    env: "env-1",
    auth: "ecvyCMlaosaZ0Ce0Xitl0Q"
  }
}

export const HeaderContent = {
  navLinks: [
    {
      text: "PLAY BEAT THE STIGMA",
      link: "/addiction-mental-health-intro"
    },
    {
      text: "LEARN ABOUT MENTAL HEALTH AND ADDICTION",
      link: "/about-addiction-mental-health-stigmas"
    },
    {
      text: "About Us",
      link: "/about-us"
    }
  ]
}

export const FooterContent = {
  footerLinks: [
    {
      text: "Facebook",
      url: "https://www.facebook.com/OhioOpioidEducationAlliance",
      className: "social-facebook"
    },
    {
      text: "Instagram",
      url: "https://www.instagram.com/ohioopioideducationalliance",
      className: "social-instagram"
    },
    {
      text: "Twitter",
      url: "https://twitter.com/OhioOpioidEdu",
      className: "social-twitter"
    },
    {
      text: "YouTube",
      url: "https://www.youtube.com/channel/UCUgAb-rTdW_4QXjf2zuR5rw",
      className: "social-youtube"
    }
  ]
}

export const LandingContent = {
  headTag: {
    title: pageTitleContent['/'],
    metaTags: [
      {
        name: "description",
        content: "Beat the Stigma is a addiction and mental health quiz game. Test your knowledge and try to answer 10 questions about mental health and substance use disorders in 60 seconds."
      },
      {
        name: 'og:description',
        content:
          'Welcome to Beat the Stigma! You have just 60 seconds to answer 10 questions about mental health and substance use disorders.'
      },
      {
        name: 'og:title',
        content: pageTitleContent['/']
      }
    ],
    script: {
      type: 'application/ld+json',
      innerHTML: videoSchema
    }
  },

  header: "Beat the Stigma",
  subHeader: "Can you",
  buttonText: "Play beat the Stigma",
  body: "Challenge what you know about mental health and addiction. Can you beat the clock—and beat the stigma?",
  learnMoreLink: "LEARN ABOUT MENTAL HEALTH AND ADDICTION",
  moreInfo: [
    {
      title: "What Are<br/>Stigmas?",
      content: "<p>Stigmas are negative beliefs or attitudes about people or topics—and they're unfortunately very common. Many people stigmatize others without even knowing it.</p><p>There are many harmful stigmas about mental illness and alcohol and drug use disorders, and they often keep people from getting the help they need. Getting to know the truth behind addiction and mental health can help everyone beat these stigmas, once and for all.</p>",
      pull: "<p><span><span>67%</span> of people</span> say it's not a problem if you discriminate against people who are dealing with an addiction</p>",
      iconClasses: "icon icon-crowd",
      iconAltText: "Two people"
    },
    {
      title: "Mental Illness Is a<br/>Medical Condition,<br/>Not a Choice",
      content: "<p>Mental illnesses, like depression and anxiety, are extremely common. One in five Americans cope with mental illness, so someone you know and love is likely one of those people.</p><p>Mental illness is a complex medical condition with underlying factors.</p>",
      pull: "<p><span>MORE THAN 50% OF AMERICANS</span> WILL BE DIAGNOSED WITH A MENTAL DISORDER IN THEIR LIFETIME</p>",
      iconClasses: "icon icon-selfcare",
      iconAltText: "Person meditating"
    },

    {
      title: "Addiction Is a<br/>Disease, Not a<br/>Decision",
      content: "<p>Addiction is a complex disease, similar to heart disease, cancer, and other chronic illnesses.</p><p>Unfortunately, many people believe the myth that addiction is purely a choice. The truth is that addiction is caused by many factors that are often outside a person's control, things like genetics and family history, living with mental illness, and having a history of trauma and abuse.</p>",
      pull: "<p><span>The genes you're born with</span> can increase your chance of developing drug or alcohol addiction by 50%</p>",
      iconClasses: "icon icon-comfort",
      iconAltText: "Two people hugging"
    }
  ],
  videoBox: {
    youtubeURL: "apURi__O388",
    header: "Watch the Advertisements",
    carouselItems: [
      "9dJea8Q1NBk",
      "JMxxa-5HZQE"
    ]
  },
  promo: {
    header: "Challenge What You<br/>Know About Mental<br/>Health and Addiction",
    copy: "Stigmas lead people to make judgements and buy into myths about mental health and drug and alcohol use disorders. The truth is, when you see someone living with mental health or substance use disorders, there's always more to the story than you know. Learn more about the real risk factors for mental illness and addiction—and how you can help.",
    button: {
      text: "Learn about mental health and addiction",
      path: "/about-addiction-mental-health-stigmas"
    }
  }
}

export const IntroContent = {
  headTag: {
    title: pageTitleContent['/addiction-mental-health-intro'],
    metaTags: [
      {
        name: "description",
        content: "Welcome to Beat the Stigma! You have just 60 seconds to answer 10 questions about mental health and substance use disorders."
      },
      {
        name: 'og:description',
        content:
          'Welcome to Beat the Stigma! You have just 60 seconds to answer 10 questions about mental health and substance use disorders.'
      },
      {
        name: 'og:title',
        content: pageTitleContent['/addiction-mental-health-intro']
      }
    ]
  },
  header: "Welcome to Beat the Stigma!",
  bodyCopy: "<p>The rules of the game are simple. You have just 60 seconds to answer 10 questions about Mental Health and Substance Use Disorders.</p><p>You'll get instant feedback as to whether you're wrong or right—and get a chance to review your responses and get more information about the correct answers at the end of the game.  </p><p>Are you ready to play?</p>",
  startLinkText: "START the GAME",
  learnMoreText: "I WANT TO LEARN MORE FIRST"
}

export const GameTimerConfig = {
  totalSeconds: 60,
  segments: 6
}

export const RandomizeQuestions = () => {
  const topics = QuestionsContent.topics;
  for (let i = topics.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * i)
    const temp = topics[i]
    topics[i] = topics[j]
    topics[j] = temp
  }

  return topics;
}

export const QuestionsContent = {
  headTag: {
    title: pageTitleContent['/addiction-mental-health-quiz'],
    metaTags: [
      {
        name: "description",
        content: "Beat the Stigma is an addiction and mental health quiz game. Test your knowledge and try to answer 10 questions about mental health and substance use disorders in 60 seconds."
      },
      {
        name: "robots",
        content: "noindex"
      }

    ]
  },
  topics: [
    {
      questionType: "choice",
      question: "Studies show you have a higher risk of developing a drug or alcohol addiction if you:",
      answers: [
        "Have a family history of addiction",
        "Lack willpower",
        "Have character weaknesses",
        "Have a dramatic personality"
      ],
      answerIndex: 0,
      topicType: "topicA",
      slug: "topicA-question-one",
      additionalInfo: "<p>Just like brown eyes or dimples, addiction can be passed down through a family's genes. That means if you have family members who have struggled with addiction, you have a higher risk of developing drug and alcohol addiction too—50 percent more risk, according to the National Institute on Drug Abuse.</p>",
      learnMore: "https://www.drugabuse.gov/publications/drugfacts/understanding-drug-use-addiction",
      userAnswer: null,
      userCorrect: null,
      userSkipped: null
    },
    {
      questionType: "choice",
      question: "Which of these is true about addiction?",
      answers: [
        "An addict can quit whenever they want",
        "Addiction is a sign of weakness",
        "Addiction physically changes your brain",
        "Addiction can't be treated"
      ],
      answerIndex: 2,
      topicType: "topicA",
      slug: "topicA-question-two",
      additionalInfo: "<p>Addiction isn't a sign of weakness or a lack of willpower. The brains of people with addiction have changed in ways that make it even harder for them to quit.  </p>",
      learnMore: "https://www.drugabuse.gov/publications/drugfacts/understanding-drug-use-addiction"
    },
    {
      questionType: "choice",
      question: "How many Americans said that they wouldn't want to work closely with someone with a drug addiction?",
      answers: [
        "45%",
        "57%",
        "78%",
        "84%"
      ],
      answerIndex: 2,
      topicType: "topicA",
      slug: "topicA-question-three",
      additionalInfo: "<p>According to the Recovery Research Institute, more than three out of every four Americans say they wouldn't want to work with someone who is coping with a drug addiction—and nine out of 10 wouldn't want someone coping with drug addiction in their family. Some of the myths about drug use disorders may be to blame. </p>",
      learnMore: "https://www.drugabuse.gov/publications/drugfacts/understanding-drug-use-addiction",
      userAnswer: null,
      userCorrect: null,
      userSkipped: null
    },
    {
      questionType: "choice",
      question: "How many Americans are coping with depression?",
      answers: [
        "1 out of every 10 ",
        "1 out of every 8",
        "1 out of every 7",
        "1 out of every 5"
      ],
      answerIndex: 3,
      topicType: "topicA",
      slug: "topicA-question-four",
      additionalInfo: "<p>One in five people are coping with depression, according to the National Institute of Mental Health. That means that someone you know is likely dealing with this common mental health disorder right now. </p>",
      learnMore: "https://www.drugabuse.gov/publications/drugfacts/understanding-drug-use-addiction"
    },
    {
      questionType: "choice",
      question: "Find the truth about mental health in the list below.",
      answers: [
        "People with mental illnesses can still work",
        "Therapy doesn't work",
        "Only adults have mental health issues",
        "People with mental illnesses are just being dramatic"
      ],
      answerIndex: 0,
      topicType: "topicA",
      slug: "topicA-question-five",
      additionalInfo: "<p>You can have mental health issues and still be successful in your career, too. In fact, one study found that depression is common among highly successful people.</p>",
      learnMore: "https://www.drugabuse.gov/publications/drugfacts/understanding-drug-use-addiction",
      userAnswer: null,
      userCorrect: null,
      userSkipped: null
    },
    {
      questionType: "choice",
      question: "Find the phrase that doesn't stigmatize people with mental health and substance use disorders.",
      answers: [
        "Insane",
        "Person with depression",
        "Addict",
        "Substance abuser"
      ],
      answerIndex: 1,
      topicType: "topicA",
      slug: "topicA-question-six",
      additionalInfo: "<p>Words matter. The way we talk about mental and substance use disorders can create shame and hurt people even if it's unintentional. It's always good to use “person centered” language and avoid hurtful or unflattering terms like addict, crazy, and junkie.  Don't let mental illness define a loved one. </p>",
      learnMore: "https://www.drugabuse.gov/publications/drugfacts/understanding-drug-use-addiction"
    },
    {
      questionType: "choice",
      question: "What are the chances that a person will develop a mental health issue at some point in their life?",
      answers: [
        "About 10%",
        "About 30%",
        "About 50%",
        "About 70%",
      ],
      answerIndex: 2,
      topicType: "topicA",
      slug: "topicA-question-seven",
      additionalInfo: "<p>Nearly half of all people will have a diagnosable mental illness at some point in their life. So someone you know is likely dealing with a mental health issue right now. </p>",
      learnMore: "https://www.drugabuse.gov/publications/drugfacts/understanding-drug-use-addiction",
      userAnswer: null,
      userCorrect: null,
      userSkipped: null
    },

    {
      questionType: "choice",
      question: "How many Ohio adults have substance use disorders?",
      answers: [
        "1 in 20",
        "1 in 17",
        "1 in 13",
        "1 in 5",
      ],
      answerIndex: 2,
      topicType: "topicB",
      slug: "topicB-question-eight",
      additionalInfo: "<p>Addiction is a real problem for many adults, especially young adults—14.6 percent of Americans 18-25 struggle with a illicit drug use disorder.</p>",
      learnMore: "https://www.samhsa.gov/data/data-we-collect/nsduh-national-survey-drug-use-and-health",
      userAnswer: null,
      userCorrect: null,
      userSkipped: null
    },

    {
      questionType: "choice",
      question: "What is true about young people and mental health?",
      answers: [
        "They don't have real problems",
        "They're more likely to have a mental illness",
        "They're more likely to be dramatic",
        "More than 50% get mental health treatment"
      ],
      answerIndex: 1,
      topicType: "topicB",
      slug: "topicB-question-nine",
      additionalInfo: "<p>Over 29 percent of young adults have a mental health issue—and a whopping 56 percent of young adults said they felt anxious or depressed during the COVID pandemic. Unfortunately, there's a myth going around that young people don't have real problems to worry about—so only 39 percent of them get treated for their mental health disorders.  </p>",
      learnMore: "https://www.drugabuse.gov/publications/drugfacts/understanding-drug-use-addiction"
    },

    {
      questionType: "choice",
      question: "Which of these is NOT a reason people with mental illness or addiction aren't getting the help they need?",
      answers: [
        "They're happy the way they are",
        "They can't afford it",
        "They don't know how to get help",
        "They don't think it'll work"
      ],
      answerIndex: 0,
      topicType: "topicB",
      slug: "topicB-question-ten",
      additionalInfo: "<p>More than half of all adults with mental health issues don't get treatment—and only 25 percent of people who needed treatment for addiction received it.</p><p>There are lots of reasons that people don't receive the care they need, including the cost, believing that the treatment won't work, and having trouble finding the right care. One big factor, though, is that many people feel ashamed and afraid that their family and friends will look down on them if they do receive treatment. </p>",
      learnMore: "https://www.drugabuse.gov/publications/drugfacts/understanding-drug-use-addiction",
      userAnswer: null,
      userCorrect: null,
      userSkipped: null
    },
    {
      questionType: "choice",
      question: "Addiction is a <span class='fillin-line span-6'>&nbsp;</span>.",
      answers: ["Choice", "Myth", "Disease", "Reason to be ashamed"],
      answerIndex: 2,
      slug: "topicB-question-eleven",
      additionalInfo: "<p>Addiction is a chronic disease, just like cancer, diabetes, and heart disease. And just as you wouldn't shame a friend who has heart disease or diabetes for causing their condition, you shouldn't shame a person who has a substance use disorder.</p>",
      learnMore: "https://www.drugabuse.gov/publications/drugfacts/understanding-drug-use-addiction"
    },
    {
      questionType: "choice",
      question: "What is the most common mental health issue?",
      answers: [
        "Anxiety",
        "Schizophrenia",
        "Depression",
        "Post-Traumatic Stress Disorder"
      ],
      answerIndex: 0,
      topicType: "topicB",
      slug: "topicB-question-twelve",
      additionalInfo: "<p>Feeling anxious? You're not alone: 40 million adults have anxiety disorders—making it the most common mental illness in the U.S. Unfortunately, only 36 percent of people with anxiety issues actually get treated for it.</p>",
      learnMore: "https://www.drugabuse.gov/publications/drugfacts/understanding-drug-use-addiction",
      userAnswer: null,
      userCorrect: null,
      userSkipped: null
    },

    {
      questionType: "choice",
      question: "Many people coping with mental illness and substance abuse disorders feel <span class='fillin-line span-6'>&nbsp;</span>, thanks to stigmas.",
      answers: [
        "In control of their lives",
        "Ashamed",
        "Ready to seek treatment",
        "Proud"
      ],
      answerIndex: 1,
      topicType: "topicB",
      slug: "topicB-question-thirteen",
      additionalInfo: "<p>The myths surrounding addiction and mental health issues make people who are dealing with them feel isolated and ashamed—even though it's a disease that's beyond their control. There's nothing to feel ashamed about.</p>",
      learnMore: "https://www.drugabuse.gov/publications/drugfacts/understanding-drug-use-addiction",
      userAnswer: null,
      userCorrect: null,
      userSkipped: null
    },
    {
      questionType: "choice",
      question: "Half of your risk of developing an alcohol or drug addiction is <span class='fillin-line span-6'>&nbsp;</span>.",
      answers: [
        "Based on your personality",
        "Dependent on what types of drugs you use",
        "Dependent on what kinds of grades you got in school",
        "Genetic"
      ],
      answerIndex: 3,
      topicType: "topicB",
      slug: "topicB-question-fourteen",
      additionalInfo: "<p>Genes play a very big role In whether you develop a substance use disorder or not. The genes you're born with can increase your chance of developing drug or alcohol addiction by 50%.</p>",
      learnMore: "https://www.drugabuse.gov/publications/drugfacts/understanding-drug-use-addiction",
      userAnswer: null,
      userCorrect: null,
      userSkipped: null
    },

    {
      questionType: "choice",
      question: "Some reasons that people develop drug and alcohol use problems include other mental health issues, genetics, and <span class='fillin-line span-6'>&nbsp;</span>.",
      answers: [

        "Hanging out with the wrong crowd",
        "Having parents that don't discipline you",
        "Trauma",
        "Being irresponsible"
      ],
      answerIndex: 2,
      topicType: "topicC",
      slug: "topicC-question-fifteen",
      additionalInfo: "<p>Past abuse or trauma in a person's life, especially in childhood, may lead them to develop a substance use disorder. In fact, researchers found that people with PTSD were two to four times more likely to have drug and alcohol abuse problems than people who don't have PTSD. Nearly half of all people with PTSD had a substance use disorder as well.</p>",
      learnMore: "https://www.drugabuse.gov/publications/drugfacts/understanding-drug-use-addiction",
      userAnswer: null,
      userCorrect: null,
      userSkipped: null
    },
    {
      questionType: "choice",
      question: "Mental illnesses are medical conditions.",
      answers: [
        "True",
        "False",
      ],
      subType: "truefalse",
      answerIndex: 0,
      topicType: "topicC",
      slug: "topicC-question-sixteen",
      additionalInfo: "<p>Whether you have depression, bipolar disorder, anxiety, or another mental health disorder, it's a disease, not a decision.</p>",
      learnMore: "https://www.drugabuse.gov/publications/drugfacts/understanding-drug-use-addiction",
      userAnswer: null,
      userCorrect: null,
      userSkipped: null
    },

    {
      questionType: "choice",
      question: "About 3 million people in the U.S. are dealing with both mental illness and addiction at the same time.",
      answers: [
        "True",
        "False",
      ],
      subType: "truefalse",
      answerIndex: 1,
      topicType: "topicC",
      slug: "topicC-question-seventeen",
      additionalInfo: "<p>The number is much higher: 7.7 million Americans have both mental illness and substance use disorders. These diseases often appear together, because they have the same risk factors, things like genetics and history of trauma.</p>",
      learnMore: "https://www.drugabuse.gov/publications/drugfacts/understanding-drug-use-addiction",
      userAnswer: null,
      userCorrect: null,
      userSkipped: null
    },

    {
      questionType: "choice",
      question: "Treatments for addiction are not effective.",
      answers: [
        "True",
        "False",
      ],
      subType: "truefalse",
      answerIndex: 1,
      topicType: "topicC",
      slug: "topicC-question-eighteen",
      additionalInfo: "<p>Addiction is a treatable disease.</p><p>There are a lot of myths about addiction—and one of the most common is that addiction isn't treatable. But treatment for addiction is as effective as many other treatments for chronic illnesses, such as diabetes and high blood pressure, according to the National Institute on Drug Abuse.</p>",
      learnMore: "https://nida.nih.gov/publications/drugs-brains-behavior-science-addiction/treatment-recovery",
      userAnswer: null,
      userCorrect: null,
      userSkipped: null
    },
    {
      questionType: "choice",
      question: "Only adults can experience mental illness.",
      answers: [
        "True",
        "False",
      ],
      subType: "truefalse",
      answerIndex: 1,
      topicType: "topicC",
      slug: "topicC-question-nineteen",
      additionalInfo: "<p>Men are among the demographics least likely to talk about mental health—only 36% of men would feel comfortable talking about mental health with their friends, compared to 50 percent of women, according to a CBS News poll.</p>",
      learnMore: "https://www.drugabuse.gov/publications/drugfacts/understanding-drug-use-addiction",
      userAnswer: null,
      userCorrect: null,
      userSkipped: null
    },

    {
      questionType: "choice",
      question: "Men are less likely to talk about their mental health than women. ",
      answers: [
        "True",
        "False",
      ],
      subType: "truefalse",
      answerIndex: 0,
      topicType: "topicC",
      slug: "topicC-question-twenty",
      additionalInfo: "<p>Men are among the demographics least likely to talk about mental health—only 36% of men would feel comfortable talking about mental health with their friends, compared to 50 percent of women, according to a CBS News poll. </p>",
      learnMore: "https://www.drugabuse.gov/publications/drugfacts/understanding-drug-use-addiction",
      userAnswer: null,
      userCorrect: null,
      userSkipped: null
    },
    {
      questionType: "choice",
      question: "The number of Ohioans who have a mental health condition are equal to:",
      answers: [
        "309,000 (the population of Cincinnati)",
        "1.9 million (5 times the population of Cleveland)",
        "1.2 million (10 times the population of Dayton)",
        "514,000 (5 sold-out Ohio Stadiums)"
      ],
      subType: "truefalse",
      answerIndex: 1,
      topicType: "topicC",
      slug: "topicC-question-twenty",
      additionalInfo: "<p>More than 1.9 million people in Ohio have a mental health condition—which is almost 5 times the population of Cleveland.</p>",
      learnMore: "https://namiohio.org/wp-content/uploads/2021/11/OhioStateFactSheet.pdf",
      userAnswer: null,
      userCorrect: null,
      userSkipped: null
    },
    {
      questionType: "choice",
      question: "In 2021, what percentage of Americans said they struggled with anxiety?",
      answers: [

        "13",
        "28",
        "75",
        "47"
      ],
      subType: "truefalse",
      answerIndex: 3,
      topicType: "topicC",
      slug: "topicC-question-twenty",
      additionalInfo: "<p>Nearly half of all Americans reported symptoms of anxiety in 2021—one of the highest rates in years, according to the U.S. Census Bureau.</p>",
      learnMore: "https://www.psychiatrictimes.com/view/psychiatric-care-in-the-us-are-we-facing-a-crisis",
      userAnswer: null,
      userCorrect: null,
      userSkipped: null
    },
    {
      questionType: "choice",
      question: "Which of these is NOT a risk factor for developing a mental health condition?",
      answers: [
        "Character flaws",
        "A family history of mental illness",
        "Experiencing a traumatic or stressful event",
        "Having a brain injury"
      ],
      subType: "truefalse",
      answerIndex: 0,
      topicType: "topicC",
      slug: "topicC-question-twenty",
      additionalInfo: "<p>Genetics, past traumas, brain injuries and chemical imbalances are the most common reasons that someone develops a mental health disorder. </p>",
      learnMore: "https://medlineplus.gov/mentaldisorders.html",
      userAnswer: null,
      userCorrect: null,
      userSkipped: null
    },
    {
      questionType: "choice",
      question: "If someone tells you they're struggling with depression, what's a supportive thing you can say to them?",
      answers: [

        "Try to snap out of it.",
        "I'm here for you.",
        "Think positive thoughts. Push through it.",
        "Your life seems great. Be happy."
      ],
      subType: "truefalse",
      answerIndex: 1,
      topicType: "topicC",
      slug: "topicC-question-twenty",
      additionalInfo: "<p>Depression is a serious mental health condition—and even people with successful, happy lives can be affected. Letting your loved one know you're there to support them can be a big step toward getting them the help they may need.</p>",
      learnMore: "https://makeitok.org/what-to-say/",
      userAnswer: null,
      userCorrect: null,
      userSkipped: null
    },
    {
      questionType: "choice",
      question: "Your child says they're feeling really anxious. What are some things you can do?  ",
      answers: [
        "Consider getting them some mental health support.",
        "Listen to them. Validate their feelings.",
        "Tell them that you're here for them.",
        "All of the above"
      ],
      subType: "truefalse",
      answerIndex: 3,
      topicType: "topicC",
      slug: "topicC-question-twenty",
      additionalInfo: "<p>Anxiety is a serious mental health issue, caused by changes in the brain. And it's something that can affect kids, too. Listen to what your child has to tell you. A meeting with a mental health professional could help you and your child find the best solution.</p>",
      learnMore: "https://makeitok.org/what-to-say/",
      userAnswer: null,
      userCorrect: null,
      userSkipped: null
    },
    {
      questionType: "choice",
      question: "Which of these statements is a word or phrase you could use to describe someone with a mental health condition?",
      answers: [

        "Attention-seeking",
        "Crazy",
        "Person with depression",
        "Sad"
      ],
      subType: "truefalse",
      answerIndex: 2,
      topicType: "topicC",
      slug: "topicC-question-twenty",
      additionalInfo: "<p>Watch your labeling! Words like crazy or insane can make people with mental health conditions feel stigmatized, while attention-seeking makes it seem like they're doing this on purpose. Using phrases like \"person with depression\" makes it clear that their mental health condition doesn't define them. </p>",
      learnMore: "https://www.mhanational.org/person-centered-language",
      userAnswer: null,
      userCorrect: null,
      userSkipped: null
    },

    {
      questionType: "choice",
      question: "<span class='fillin-line span-6'>&nbsp;</span> is a leading cause of disability worldwide.",
      answers: [

        "Heart disease",
        "Back pain",
        "Diabetes",
        "Depression"
      ],
      subType: "truefalse",
      answerIndex: 3,
      topicType: "topicC",
      slug: "topicC-question-twenty",
      additionalInfo: "<p>Depression is the top cause of disability worldwide.</p>",
      learnMore: "https://www.who.int/news-room/fact-sheets/detail/depression",
      userAnswer: null,
      userCorrect: null,
      userSkipped: null
    },

    {
      questionType: "choice",
      question: "The number of people with depression worldwide is closest to the population of <span class='fillin-line span-6'>&nbsp;</span>.",
      answers: [
        "the United States",
        "India",
        "Russia",
        "Japan"
      ],
      subType: "truefalse",
      answerIndex: 0,
      topicType: "topicC",
      slug: "topicC-question-twenty",
      additionalInfo: "<p>More than 280 million people around the world have depression—very close to the U.S. population of 332 million. </p>",
      learnMore: "https://www.who.int/news-room/fact-sheets/detail/depression",
      userAnswer: null,
      userCorrect: null,
      userSkipped: null
    },

    {
      questionType: "choice",
      question: "Half of all chronic mental health conditions begin before age <span class='fillin-line span-6'>&nbsp;</span>.",
      answers: [
        "30",
        "18",
        "14",
        "45"
      ],
      subType: "truefalse",
      answerIndex: 2,
      topicType: "topicC",
      slug: "topicC-question-twenty",
      additionalInfo: "<p>Many mental health conditions show up in children or teens. For half of the people who have chronic mental health conditions, their symptoms began by the time they became teenagers.</p>",
      learnMore: "https://www.onoursleeves.org/about/research",
      userAnswer: null,
      userCorrect: null,
      userSkipped: null
    },

    {
      questionType: "choice",
      question: "Mental health conditions are common. In fact, around <span class='fillin-line span-6'>&nbsp;</span> percent of Americans will be diagnosed with one at some point in their lifetime.",
      answers: [

        "80",
        "40",
        "30",
        "50",
      ],
      subType: "truefalse",
      answerIndex: 3,
      topicType: "topicC",
      slug: "topicC-question-twenty",
      additionalInfo: "<p>Odds are, you know plenty of people who have had a mental health condition. More than 50 percent of Americans will be diagnosed with a mental illness or disorder at some point in their lifetime.</p>",
      learnMore: "https://www.cdc.gov/mentalhealth/learn/index.htm",
      userAnswer: null,
      userCorrect: null,
      userSkipped: null
    },

    {
      questionType: "choice",
      question: "True or false: Physical changes in the brain can cause depression and anxiety.",
      answers: [
        "True",
        "False"
      ],
      subType: "truefalse",
      answerIndex: 0,
      topicType: "topicC",
      slug: "topicC-question-twenty",
      additionalInfo: "<p>Mental health conditions are a result of changes in the brain, including chemical imbalances and the way that parts of the brain interact with each other. Fortunately, there are successful treatments to help people with depression, anxiety, and other mental health conditions.</p>",
      learnMore: "https://www.health.harvard.edu/mind-and-mood/what-causes-depression ",
      userAnswer: null,
      userCorrect: null,
      userSkipped: null
    }
  ]
}

export const ResultsContent = {
  headTag: {
    title: pageTitleContent['/game-results'],
    metaTags: [
      {
        name: "description",
        content: "Did you separate the myths about mental health and drug and alcohol use disorders from the truth? See what questions you got correct and play again!"
      }
    ]
  },
  tierOne: {
    questionCount: 0,
    correctCount: 0,
    hero: {
      header: "You Beat the Stigma!",
      bodyCopy: "Congratulations! You really know how to separate fact from fiction when it comes to mental health and addiction. Now that you've beaten the stigma, you can play again with new questions. Or, stay on top of your game by reading a little bit more about mental illness and substance use disorders—and spread your knowledge to others by sharing what you know about mental health and addiction."
    }

  },
  tierTwo: {
    questionCount: 0,
    correctCount: 0,
    hero: {
      header: "You Came So Close to Beating the Stigma!",
      bodyCopy: "You got through all 10 questions—and you have a pretty good handle on how addiction and mental illness are stigmatized right now. Play again to see if you can beat the stigma!"
    }

  },
  tierThree: {
    questionCount: 0,
    correctCount: 0,
    hero: {
      header: "You're On Your Way to Beating the Stigma!",
      bodyCopy: "You seem to know quite a bit about mental illness and alcohol and drug use disorders. But there's still more you can learn to help you stop stigmas in their tracks. Take another pass and see if you can beat the stigma!"
    }

  },
  tierFour: {
    questionCount: 0,
    correctCount: 0,
    hero: {
      header: "You Beat the Clock, But Not the Stigma",
      bodyCopy: "It can be hard to separate fact from fiction when it comes to mental health and addiction—there's a lot of false information out there. But you're in the right place to learn the truth about the causes of mental illness and drug and alcohol use disorders—so you can help people beat the stigma, once and for all. Try again to see if you can beat the stigma this time!"
    }

  },
  tierFive: {
    questionCount: 0,
    correctCount: 0,
    hero: {
      header: "Sorry! You Didn't Beat the Stigma",
      bodyCopy: "It can be really hard to separate the myths about mental health and drug and alcohol use disorders. But you're definitely in the right place to start learning how to separate fact from fiction. You can learn more about the causes of mental illness and drug and alcohol use disorders, or you can try the game again to see if you can get closer to beating the stigma."
    }
  },

  tierSix: {
    questionCount: 0,
    correctCount: 0,
    hero: {
      header: "You Beat the Stigma!",
      bodyCopy: "Congratulations! You really know how to separate fact from fiction when it comes to mental health and addiction. Now that you've beaten the stigma, you can play again with new questions. Or, stay on top of your game by reading a little bit more about mental illness and substance use disorders—and spread your knowledge to others by sharing what you know.  "
    }
  },

  tierSeven: {
    questionCount: 0,
    correctCount: 0,
    hero: {
      header: "You're on Your Way to Beating the Stigma!",
      bodyCopy: "You seem to know quite a bit about mental illness and alcohol and drug use disorders. But there's still more you can learn to help you stop stigmas in their tracks. Take another pass and see if you can beat the stigma!"
    }
  },
  tierEight: {
    questionCount: 0,
    correctCount: 0,
    hero: {
      header: "Sorry! You Didn't Beat the Stigma",
      bodyCopy: "It can be really hard to get a handle on what's true and what's not about mental health and drug and alcohol use disorders. But you're definitely in the right place to start learning how to separate fact from fiction. You can learn more about the causes of mental illness and drug and alcohol use disorders, or you can try the game again to see if you can get closer to beating the stigma."
    }
  }
}

export const moreAboutContent = {
  headTag: {
    title: pageTitleContent['/about-addiction-mental-health-stigmas'],
    metaTags: [
      {
        name: "description",
        content: "Learn more about addiction and mental health stigmas, risk factors for addiction, and how to help someone with addiction."
      },
      {
        name: 'og:description',
        content:
          'Learn more about addiction and mental health stigmas, risk factors for addiction, and how to help someone with addiction.'
      },
      {
        name: 'og:title',
        content: pageTitleContent['/about-addiction-mental-health-stigmas']
      }
    ]
  },

  textHero: {
    header: "Learn About Mental Health<br/>and Addiction",
    copy: "Stop the stigmas about mental health and substance use disorders in their tracks. Learn the facts about mental illness and addiction—and how you can help. "
  },
  riskFactor: {

    primaryMessage: {
      header: "Risk Factors for Mental Illness and Addiction ",
      copy: "<p>For people who are living with mental health or substance use disorders, it isn't about a choice or lack of willpower. Just like other health issues like diabetes, cancer, and heart disease, it's often a result of things that they cannot help, such as family history, or trauma and abuse in their past.</p><p>That doesn't mean that if you have these risk factors, you'll definitely develop addiction or mental health disorders. But it's important to understand what could put you and your loved ones at risk.</p>"
    },

    moreInfo: [
      {
        title: "Genetics &amp; Family<br/>History Can Increase<br/>Your Risk of Mental<br/>Health or Substance<br/>Use Disorders",
        content: "<p>Families can carry genes that make them more likely to develop an addiction or mental illness. If a person's parents had substance use disorders, they're more likely to develop them as well. Major psychiatric disorders also run in families—a person is 60 to 80 percent more likely to have bipolar disorder if a family member has it, and 40 to 50 percent more likely to be depressed if depression runs in their family.</p>",
        pull: "<p><span>The genes you're born with</span> can increase your chance of developing drug or alcohol addiction by <span><span>50%</span></span></p>",
        iconClasses: ""
      }
    ],
    moreInfoTwo: [
      {
        title: "Traumatic Experiences Can Put You at Risk of Mental Illness or Addiction",
        content: "<p>When terrible things happen to someone, it can affect a person's life permanently — causing mental health conditions like post-traumatic stress disorder (PTSD), depression, and anxiety. A history of traumatic experiences can put you at risk for developing substance use and mental health disorders.</p>",
        pull: "<p>Teens who had experienced abuse are <span>3 times more likely</span> to develop addictions</p>",
        iconClasses: ""
      }
    ],

    learnMorePromo: {
      header: "Learn more about the risk factors",
      boxes: [
        {
          header: "For Addiction",
          linkText: "visit drugfree.org",
          url: "https://drugfree.org"
        },
        {
          header: "For Mental Illness",
          linkText: "visit americanmentalwellness.org",
          url: "https://americanmentalwellness.org"
        }

      ]
    }
  },

  howToHelp: {
    headTag: {
      title: pageTitleContent['/more-about/how-to-help-addiction'],
      metaTags: [
        {
          name: "description",
          content: "Find out how you can help someone with addiction and mental health through addiction and mental illness resources and how to destigmatize these diseases. "
        }
      ]
    },
    primaryMessage: {
      header: "How You Can Help Someone Dealing With Mental Health or Addiction",
      copy: "<p>If someone you know is dealing with a mental illness or addiction, you may have seen firsthand how discrimination and stigmas can affect them. Here are just a few easy things you can do to help them. </p>"
    },
    moreInfo: [
      {
        title: "Make Sure They Get the Help They Need",
        content: "<p>If you know someone who needs help, text 4HOPE to 741 741 to reach the Crisis Text Line or click the link below to find out about treatment options near you.</p>",
        secondaryLink: {
          header: "Learn about treatment options",
          text: "Visit SAMHSA.gov",
          url: "https://www.samhsa.gov/families"
        },
        pull: "<p>Crisis Text Line <span>text 4HOPE<br/>to 741 741</span> or</p>",
        pullButton: {
          text: "Contact Ohio CareLine",
          url: "https://mha.ohio.gov/wps/portal/gov/mha/get-help/get-help-now/ohio-careline"
        },
        iconClasses: ""
      },
      {
        title: "SHOW YOUR SUPPORT",
        content: "<p>When someone tells you they're dealing with a mental illness, the best thing you can do is listen to them. Let them know that you're there for them, whether they need someone to talk to, or someone to help them access additional help.</p><p>Don't dismiss their feelings by suggesting that everyone feels that way, or that they should just push through it without help.</p>",

        pull: "<p>LEARN HOW TO <span>SUPPORT A LOVED ONE</span></p>",
        pullButton: {
          text: "VISIT MAKEITOK.ORG",
          url: "https://makeitok.org/what-to-say/"
        },
        secondaryPullButton: {
          text: "VISIT BE PRESENT OHIO",
          url: "https://bepresentohio.org/helpful-conversations/"
        },
        iconClasses: ""
      }
    ],
    learnMorePromo: {
      header: "Get help with what you should say",
      boxes: [
        {
          linkText: "Visit Drugfree.org",
          url: "https://drugfree.org/article/when-addiction-is-in-your-family-tree/"
        },
        {
          linkText: "Visit Onoursleeves.org",
          url: "https://www.onoursleeves.org/mental-health-resources/articles-support/addiction"
        }

      ]
    }
  }
}
