import React from "react";
import Parser from 'html-react-parser';
import './two-column-promo.scss';

const TwoColumnPromo = ({ content }) => {

  return (
    <div className="section two-column-promo">
      <div className="section-inner">
        <h3>{Parser(content.header)}</h3>
        <div className="promos">
          {content.boxes.map((box, idx) =>
            <div className="promo-box" key={`${box.header}-${idx}`}>
              <a href={box.url} target="_blank" rel="noreferrer">{box.linkText}</a>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default TwoColumnPromo;
