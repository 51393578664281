import { gtmConfig as config } from '../content/quiz-content'

export const gtmManager = (location) => {
  let gtm = {};

  gtm.id = config.id;
  if (location.indexOf('dev.') > -1 || location.indexOf('qa.') > -1 || location.indexOf('stage.') > -1 || location.indexOf('localhost') > -1) {
    gtm.config = config.stage;
  } else {
    gtm.config = config.prod;
  }

  return gtm;
}
