import React from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import AboutUsResources from './AboutUsResources';
import HeadTagManager from '../../utils/HeadTagManager';
import AboutUsPartners from './AboutUsPartners';

const AboutUs = ({ match }) => {
  const content = {
    title: 'Mental Health and Addiction Resources | Ohio Opioid Education Alliance',
    metaTags: [
      {
        name: 'description',
        content:
          'Get more information about mental health and addiction resources in Ohio. Read the latest articles about addiction and mental health, and Beat the Stigma partners.'
      },
      {
        name: 'og:description',
        content:
          'Get more information about mental health and addiction resources in Ohio. Read the latest articles about addiction and mental health, and Beat the Stigma partners.'
      },
      {
        name: 'og:title',
        content: 'Mental Health and Addiction Resources | Ohio Opioid Education Alliance'
      }
    ]
  };

  return (
    <>
      <HeadTagManager title={content.title} metaTags={content.metaTags} />
      <div className="tabbed-content">
        <div className="section tabbed-content-hero">
          <div className="section-inner">
            <div className="column">
              <h1>About Us</h1>
              <p>Beat the Stigma is funded by the Ohio Opioid Education Alliance.</p>
            </div>
          </div>
        </div>

        <Tabs>
          <div className="section tabs-container">
            <div className="section-inner">
              <TabList>
                <Tab>Resources</Tab>
                <Tab>Partners</Tab>
              </TabList>
            </div>
          </div>
          <TabPanel>
            <AboutUsResources />
          </TabPanel>
          <TabPanel>
            <AboutUsPartners />
          </TabPanel>
        </Tabs>
      </div>
    </>
  )
}

export default AboutUs;
