import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import GameTimer from '../GameTimer/GameTimer';
import Question from '../Question/Question';
import HeadTagManager from '../../utils/HeadTagManager';
import { QuestionsContent } from '../../content/quiz-content';
import {
  setUserAnswers,
  setResultsContent,
  selectGameTimerOff
} from '../../redux/slices/gameSlice';
import { ResultsContent } from '../../content/quiz-content';
import { GameTimerConfig } from '../../content/quiz-content';
import { RandomizeQuestions } from '../../content/quiz-content';
import './quiz.scss';

const Quiz = () => {
  const questionsPool = [...RandomizeQuestions()];
  const questions = questionsPool.slice(0, 10);
  const reserveQuestions = questionsPool.slice(10, questionsPool.length);
  const questionsLen = questions.length;
  const history = useHistory();
  const dispatch = useDispatch();
  const gameTimerOff = useSelector(selectGameTimerOff);

  // internal state
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [isIncorrect, setIsIncorrect] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [questionsSet, setQuestionsSet] = useState(questions);
  const [reservedSet, setReservedSet] = useState(reserveQuestions);
  const [isSkipped, setIsSkipped] = useState(false);
  const [showNextButton, setShowNextButton] = useState(false);
  const [answers, setAnswers] = useState([]);

  useEffect(() => {
    // console.log('questionsSet ', questionsSet, '\nreservedSet ', reservedSet);
  }, [questionsSet, reservedSet]);

  const questionContent = useRef();

  const skipQuestion = () => {
    if (isPaused) return;
    setIsSkipped(true);
    setIsPaused(true);
    let idx = currentQuestionIndex;
    let temp = [...questionsSet];
    let reserveTemp = [...reservedSet];

    // copy first item in the reserve array
    let reserve = reserveTemp[0];
    // delete it from reserve array
    reserveTemp.splice(0, 1);
    // add it to the last position of the questionsSet
    temp.splice(temp.length, 0, reserve);

    // copy the skipped question
    let skippedQuestion = temp[idx];
    // remove it from questionSet
    temp.splice(idx, 1);
    //add it to last position in reservedSet
    reserveTemp.splice(reserveTemp.length, 0, skippedQuestion);

    setReservedSet(() => reserveTemp);
    setQuestionsSet(() => temp);

    setTimeout(() => {
      setIsPaused(false);
      setIsSkipped(false);
    }, 1200);
  };

  const handleAnswer = value => {
    let nextIndex = currentQuestionIndex + 1;
    let answerObj = { ...questionsSet[currentQuestionIndex] };
    let answerIdx = answerObj.answerIndex;

    answerObj.userAnswer = value;
    answerObj.userSkipped = false;
    if (answerIdx !== value) {
      setIsIncorrect(true);
      answerObj.userCorrect = false;
    } else {
      setIsIncorrect(false);
      answerObj.userCorrect = true;
    }

    setIsPaused(true);
    dispatch(setUserAnswers(answerObj));
    let answersArr = answers;
    answersArr.push(answerObj);
    setAnswers(answersArr);

    if (!gameTimerOff) {
      setTimeout(() => {
        setIsPaused(false);
        setIsIncorrect(false);
        setIsSkipped(false);
        if (nextIndex === questionsLen) {
          handleTimeout();
        } else {
          setCurrentQuestionIndex(nextIndex);
        }
      }, 1200);
    } else {
      setShowNextButton(true);
    }
  };

  const handleNextButton = () => {
    setIsPaused(false);
    setIsIncorrect(false);
    setIsSkipped(false);
    if (currentQuestionIndex + 1 === questionsLen) {
      handleTimeout();
    } else {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setShowNextButton(false);
    }
  };

  const handleTimeout = () => {

    let correctCount = 0;
    let contentObj = {};
    let tier;
    answers.forEach((answer, idx) => {
      const correct = answer.userCorrect;
      if (correct) correctCount++;
    });

    if (!gameTimerOff) {
      if (correctCount === 10 && answers.length === 10) {
        contentObj = ResultsContent['tierOne'];
        tier = 1;
      } else if (
        correctCount > 5 &&
        correctCount < 10 &&
        answers.length === 10
      ) {
        contentObj = ResultsContent['tierTwo'];
        tier = 2;
      } else if (
        correctCount <= answers.length &&
        correctCount >= 6 &&
        answers.length < 10
      ) {
        contentObj = ResultsContent['tierThree'];
        tier = 3;
      } else if (correctCount <= 5 && answers.length === 10) {
        contentObj = ResultsContent['tierFour'];
        tier = 4;
      } else if (correctCount < 6 && answers.length < 10) {
        contentObj = ResultsContent['tierFive'];
        tier = 5;
      }
    } else if (gameTimerOff) {
      if (correctCount === 10) {
        contentObj = ResultsContent['tierSix'];
        tier = 1;
      } else if (correctCount >= 6) {
        contentObj = ResultsContent['tierSeven'];
        tier = 1;
      } else if (correctCount < 6) {
        contentObj = ResultsContent['tierEight'];
        tier = 5;
      }
    }

    const valuesObj = {
      questionCount: answers.length,
      correctCount: correctCount,
      tier: tier
    };
    const mergedContentObj = Object.assign(contentObj, valuesObj);
    dispatch(setResultsContent({ ...mergedContentObj }));
    history.push('/game-results');
  };

  useLayoutEffect((c, x) => {
    setTimeout(() => {
      questionContent.current.focus();
    }, 500);
  }, [currentQuestionIndex, questionsSet]);

  return (
    <div className={`quiz-container ${isIncorrect ? 'incorrect' : ''}`}>
      <HeadTagManager
        title={QuestionsContent.headTag.title}
        metaTags={QuestionsContent.headTag.metaTags}
      />
      {!gameTimerOff && (
        <GameTimer
          config={GameTimerConfig}
          isPaused={isPaused}
          handleTimeout={handleTimeout}
        />
      )}

      <div className="answer-bar-wrapper" aria-live="polite" aria-atomic="true">
        {gameTimerOff && (
          <div
            className={`answer-bar answer-placeholder ${isPaused ? 'paused' : ''
              }
        `}
          />
        )}

        {!isIncorrect && !isSkipped && isPaused && (
          <div className="answer-bar answer-correct"><span className="sr-only">Your answer is </span> Correct</div>
        )}
        {isIncorrect && !isSkipped && isPaused && (
          <div className="answer-bar answer-incorrect"><span className="sr-only">Your answer is </span>Incorrect</div>
        )}
        {isSkipped && isPaused && (
          <div className="answer-bar answer-skipped"><span className="sr-only">You have </span>Skipped<span className="sr-only"> the question.</span></div>
        )}
      </div>

      <div className="section">
        <div className="section-inner">
          <div className={`quiz-stage ${gameTimerOff ? 'timer-off' : ''}`}>
            <div className="quiz-stage-inner" ref={questionContent} tabIndex="-1">
              <span>Question</span>
              <h3 className={`question-number ${isIncorrect && isPaused ? 'incorrect' : ''}`}>
                <span className="notranslate">{currentQuestionIndex + 1} / {questionsLen}</span>
              </h3>
              <Question
                handleParentAnswer={handleAnswer}
                isPaused={isPaused}
                isIncorrect={isIncorrect}
                isSkipped={isSkipped}
                config={questionsSet[currentQuestionIndex]}
                key={questionsSet[currentQuestionIndex].slug}
              />
            </div>
            <div>
              {!showNextButton ? (
                <button
                  className={`skip-question ${isPaused ? 'paused' : ''}`}
                  onClick={skipQuestion}
                >
                  Skip Question
                </button>
              ) : null}
              {showNextButton ? (
                <button
                  className="next-button"
                  onClick={handleNextButton}
                >
                  {currentQuestionIndex + 1 !== questionsLen ? "Next Question" : "View your results"}
                </button>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Quiz;
