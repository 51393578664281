import React from 'react';
import { Link } from 'react-router-dom';
import HeadTagManager from '../../utils/HeadTagManager';
import './404.scss';

const PageNotFound = () => {
  return (
    <>
      <HeadTagManager
        title="Page Not Found"
        metaTags={[
          {
            name: "robots",
            content: "noindex"
          }
        ]}
      />
      <div className="section page-not-found">
        <div className="section-inner">
          <h1>Oops!<br/>It looks like you've taken a wrong turn.</h1>
          <Link className="button glow-blue" to="/">Go to Homepage</Link>
        </div>
      </div>
    </>
  )
}

export default PageNotFound;
