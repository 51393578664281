import React, { useState, useEffect } from 'react';
import Parser from 'html-react-parser';
import Answer from './Answer';

const Question = ({ config, handleParentAnswer, isPaused, isIncorrect, isSkipped }) => {
  const [currentConfig, setCurrentConfig] = useState(config);

  useEffect(() => {
    setCurrentConfig(() => config);
  }, [config]);

  const handleAnswer = (index) => {
    handleParentAnswer(index);
  }

  return (
    <>
      <p className="quiz-question">{Parser(currentConfig.question)}</p>
      <div className="answer-container">
        {currentConfig.answers.map((answer, idx) =>
          <Answer
            key={`${answer}-${idx}`}
            answer={answer}
            index={idx}
            isPaused={isPaused}
            isSkipped={isSkipped}
            isIncorrect={isIncorrect}
            correctAnswer={config.answerIndex}
            handleAnswer={handleAnswer} />
        )}
      </div>
    </>
  )
}

export default Question;
