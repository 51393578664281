import React from "react";
import { Helmet } from 'react-helmet-async';

const HeadTagManager = ({ title, description, script, metaTags = []}) => {
  return (

      <Helmet
        title = {title}
        htmlAttributes={{ lang: "en" }}
        meta={metaTags}
        script={script && script.type && script.innerHTML ? [{
          type: script.type,
          innerHTML: script.innerHTML
        }] : []}
      />

  )
}

export default HeadTagManager;
